import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MainMenuElementGenericComponent } from './components/main-menu-element-generic/main-menu-element-generic.component';
import { MainMenuElementBatchComponent } from './components/main-menu-element-batch/main-menu-element-batch.component';
import { MainMenuElementLinkComponent } from './components/main-menu-element-link/main-menu-element-link.component';
import {SharedMaterialModule} from '../shared/shared-material.module';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {DynamicNavigationService} from './dynamic-navigation.service';

@NgModule({
    imports: [
        CommonModule,
        SharedMaterialModule,
        SharedModule,
        RouterModule
    ],
    declarations: [MainMenuComponent, MainMenuElementGenericComponent, MainMenuElementBatchComponent, MainMenuElementLinkComponent],
    exports: [
        MainMenuComponent
    ],
    providers: []
})
export class NavigationModule {

    static forRoot(): ModuleWithProviders<NavigationModule> {

        return {
            ngModule: NavigationModule,
            providers: [
                DynamicNavigationService
            ]
        };
    }
}
