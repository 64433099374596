import {Component, Input} from '@angular/core';
import {
    ActivityGranule,
    ActivityReference, AnswerAppInterface,
    AnswerInterface,
    FlashCardsContent,
} from '@modules/activities/core/models';
import {AnswersContent} from 'app/@modules/activities/core/models/activities/activity-contents';
import {isArray} from 'lodash-es';
import {MediaContent} from '@modules/activities/core/models/activities/activity-contents/media.content';
import {Observable, of, shareReplay} from 'rxjs';
import {map} from 'rxjs/operators';
import {FillInBlanksActivityGranule} from '@modules/activities/core/models/activities/typologies/fill-in-blanks-activity.granule';
import {
    ShortAnswerActivityGranule
} from '@modules/activities/core/models/activities/typologies/short-answer-activity.granule';
import {
    OrderingMatchingActivityGranule
} from "@modules/activities/core/models/activities/typologies/ordering-matching-activity.granule";
import {ToolActivityGranule} from "@modules/activities/core/models/activities/typologies/tool-activity.granule";
import { TypologyLabel } from '@modules/activities/core/typologies/typology.label';

@Component({
    selector: 'app-activity-sample',
    templateUrl: './activity-sample.component.html'
})
export class ActivitySampleComponent {
    @Input() activity!: ActivityGranule;

    protected readonly TypologyLabel = TypologyLabel;


    public getTypology(): TypologyLabel {
        return this.activity?.attributes?.metadatas?.typology?.label;
    }

    public getAnswers(): AnswerInterface[] | AnswerAppInterface[] {
        const content = (<AnswersContent>this.activity?.attributes?.reference?.activity_content);
        return ((content?.answers as AnswerInterface[]) ?? (content?.answers_app as AnswerAppInterface[]) ?? []);
    }

    public getWording() {
        const reference = (<ActivityReference<unknown>>this.activity?.attributes?.reference);
        return reference.wording;
    }

    public getTypeOfSortMatActivity() {
        const activity = this.activity as OrderingMatchingActivityGranule;
        const config = activity?.attributes?.reference?.config;
        return config?.type;
    }

    public getTypeOfToolActivity() {
        const activity = this.activity as ToolActivityGranule;
        const config = activity?.attributes?.reference?.config;
        return config?.tool;
    }

    public areAnswersAreImage(answers: AnswerInterface[]): boolean {
        return answers.every(answer => !!answer.image);
    }

    public areAnswserAreFlashcards(answers: AnswerInterface[]): boolean {
        return answers.every(answer => !!answer.flashcards && answer.flashcards.length > 0);
    }

    public areAnswersAreText(answers: AnswerInterface[]): boolean {
        return this.areAnswersAreImage(answers) === false
            && this.areAnswersAreAudio(answers) === false
            && this.areAnswserAreFlashcards(answers) === false
            && this.undefinedAnswersType(answers) === false;
    }

    public undefinedAnswersType(answers: AnswerInterface[]): boolean {
        // if some answers are image but not all
        if (answers.some(answer => !!answer.image) && !this.areAnswersAreImage(answers)) {
            return true;
        }
        // if some answers are audio but not all
        if (answers.some(answer => typeof answer.answer === 'string' && answer.answer.includes('<audio')) && !this.areAnswersAreAudio(answers)) {
            return true;
        }

        return false;
    }

    public areAnswersAreAudio(answers: AnswerInterface[]): boolean {
        return answers.every(a =>
            typeof a.answer === 'string'
            && a.answer.includes('<audio') || !!a.audio
        );
    }

    public getMultimediaActivityMedias() {
        const activity_content = isArray(this.activity.attributes.reference.activity_content)
            ? this.activity.attributes.reference.activity_content
            : [this.activity.attributes.reference.activity_content];

        const multimediaActivityContent = activity_content as (MediaContent | null)[];

        const r = multimediaActivityContent
            .filter(ac => !!ac !== false)
            .map(ac => ac.granule)
            .reduce((acc, val) => acc.concat(val), []);

        return r;
    }

    public getFlashcardActivity() {
        const activity_content = isArray(this.activity.attributes.reference.activity_content)
            ? this.activity.attributes.reference.activity_content
            : [this.activity.attributes.reference.activity_content];

        const flashcardActivityContent = activity_content as (FlashCardsContent | null)[];

        const r = flashcardActivityContent
            .filter(ac => ac !== null)
            .map(ac => ac.flashcards)
            .reduce((acc, val) => acc.concat(val), []);

        return r;
    }

    private getContentType(contents: string[]): 'img' | 'audio' | 'text' {
        const imgRegex = /<img[^>]+>/g;
        const audioRegex = /<audio[^>]+>/g;

        const isImg = contents.some(content => imgRegex.test(content));
        const isAudio = contents.some(content => audioRegex.test(content));

        if (isImg && !isAudio) {
            return 'img';
        } else if (!isImg && isAudio) {
            return 'audio';
        } else if (!isImg && !isAudio) {
            return 'text';
        } else {
            throw new Error('All contents are not of the same type');
        }
    }

    public getSourcesOfTextMatching() {
        const answers = this.getAnswers();
        return answers.map(answer => answer.source);
    }

    public getSourceTypeOfTextMatching(): 'img' | 'audio' | 'text'  {
        const sources = this.getSourcesOfTextMatching();
        return this.getContentType(sources);
    }

    public getTargetsOfTextMatching() {
        const answers = this.getAnswers();
        return answers.map(answer => answer.target);
    }

    public getTargetTypeOfTextMatching(): 'img' | 'audio' | 'text' {
        const targets = this.getTargetsOfTextMatching();
        return this.getContentType(targets);
    }

    public guessWordingOrInstructionImage$: Observable<{ alt: string; uri: string }> = of([]).pipe(
        map(() => {
            const rawWording = this.activity.get('reference').wording;
            const rawInstruction = this.activity.get('reference').instruction;
            const globalRawData = rawWording + rawInstruction;

            const imgTagRegex = /<img[^>]+>/g;

            if (!imgTagRegex.test(globalRawData)) {
                return null;
            }


            const srcRegex = /src=(["'])(.*?)\1/;
            const altRegex = /alt=(["'])(.*?)\1/;


            return {
                uri: srcRegex.exec(globalRawData)?.[2],
                alt: altRegex.exec(globalRawData)?.[2]
            };
        }), shareReplay(1));

    public getFillBlanksTextParts(): { isBlank: boolean; text: string }[] {
        function transformText(text: string): Array<{ isBlank: boolean, text: string }> {
            const parts = text.match(/(?:\[\])|[^[\]]+/g) || [];
            const result = [];

            for (const part of parts) {
                if (part === '[]') {
                    result.push({ isBlank: true, text: '[]' });
                } else {
                    result.push({ isBlank: false, text: part });
                }
            }

            return result;
        }

        const activity = this.activity as FillInBlanksActivityGranule;
        const activityContent = activity.attributes.reference.activity_content;
        const filledTexts = activityContent.answers.map(answer => ({text: JSON.parse(answer.answer)[0], isBlank: true}));

        const safeTexts = transformText(activityContent.answer_text.replace('_', ''));

        if (filledTexts.length !== safeTexts.filter(textPart => textPart.isBlank).length) {
            throw new Error('The number of blanks does not match the number of answers : ' + activity.id);
        }

        safeTexts
            .filter(tp => tp.isBlank)
            .forEach((textPart, index) => textPart.text = filledTexts[index].text);

        return safeTexts;

    }

    public isLongAnswerCRT(): boolean {
        const activity = this.activity as ShortAnswerActivityGranule;
        return activity.attributes.reference.config?.longAnswer;
    }

    public isWordingExist(): boolean {
        return this.activity.attributes.reference.wording !== '' && this.activity.attributes.reference.wording !== null;
    }
}
