import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-score-indicator',
    templateUrl: './score-indicator.component.html',
    styleUrls: ['./score-indicator.component.scss']
})
export class ScoreIndicatorComponent {
    @Input() private score: number;
    /** List of percentage steps for styling in descending order. Default value : [100, 90, 70, 50, 0] */
    @Input() private steps = [100, 90, 70, 50, 0];
    
    public get scoreStep(): number {
        const step = this.steps.find(step => this.score >= step);
        return step;
    }
}