export const corpus = {
    accessMatrix: {
        global: {
            view: ['trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            selectAll: ['learner', 'trainer', 'manager', 'administrator']
        },
        userCorpus: {
            view: ['trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            selectAll: ['learner', 'trainer', 'manager', 'administrator']
        }
    },
    addToLesson: true,
    allowedExtensions: 'jpg, jpeg, png, gif, mp4, mpeg, mp3, pdf',
    allowErrorReporting : true,
    assetFields : {
        default : ['name', 'scolomDate', 'source', 'description', 'educationalLevel', 'indexation', 'author', 'difficulty', 'skills']
    },
    assetRequiredFields: ['name'],
    assetTextEditorFields: ['description'],
    detailsFields: ['learning-time', 'concepts', 'chapters', 'levels', 'difficulty', 'skills', 'created', 'changed', 'scolomDate', 'source-author', 'source', 'format', 'description', 'indexation', 'size'],
    authorRolesGeneralCorpus: ['3', '4'], // 3 => administrator, 4 => gestionnaire, 5 => formateur, 6 => apprenant
    cardLayout: 'card-split-media-info',
    columns: {
        default: ['checkbox', 'icon', 'title', 'difficulty', 'skills', 'favorite', 'buttonsAction']
    },
    fileMaxSize: '1',
    filesWhiteList: [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/gif',
        'video/mp4',
        'video/mpeg',
        'audio/mp3',
        'audio/mpeg',
        'application/pdf'
    ],
    filters: {
        administrator: ['chapters', 'title', 'keywords', 'group', 'type', 'difficulty', 'source', 'concepts', 'favorites'],
        manager: ['chapters', 'title', 'keywords', 'group', 'type', 'difficulty', 'source', 'concepts', 'favorites'],
        default: ['chapters', 'title', 'keywords', 'group', 'type', 'difficulty', 'concepts', 'favorites']
    },
    globalCorpus: '11057',
    mediaTypes: ['audio', 'document', 'image', 'text', 'url', 'video'], // 'audio', 'document', 'image', 'media', 'text',  'url', 'video', 'videoUrl'
    showButtonOptions: true,
    terms: {
        globalCorpus:  {
            title: 'generic.title_global_corpus',
            columns: {
                type: 'generic.type_global_corpus',
                author: 'generic.author_global_corpus',
                group: 'generic.group_global_corpus',
            }
        },
        research: {
            title: 'projects-management.research_space',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            }
        },
        lesson: {
            title: 'generic.title_lesson_corpus',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            }
        },
    },
    textEditor: {
        enabled: true,
        config: {toolbar: ['bold', 'italic']},
    },
    userCorpus: true, // define if there is a corpus to list current user assets
    displayHeader: true,
};
