import { Component, OnInit } from '@angular/core';
import {HomePageService} from '@fuse/components/home-page/home-page.service';
import {DataCollection, DataEntity} from 'octopus-connect';
import {DatacardService} from '../../../app/shared/datacard.service';
import {Router} from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {titleTextInHomePageBanner} from '../../../app/settings';

declare var require: any;
const urlParser = require('js-video-url-parser');

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

    public content: string;
    public dataCards: string;
    public dialogRedirectMessage: string;
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public imgBannerUrl: string;
    public imgLogoUrl: string;
    public title: string;
    private homePageData: DataEntity;
    public titleTextInHomePageBanner: boolean = titleTextInHomePageBanner;

    constructor(public homeService: HomePageService,
                public datacardService: DatacardService,
                private router: Router,
                private dialog: MatDialog,
                private translate: TranslateService,
                private communicationCenter: CommunicationCenterService) { }

    ngOnInit(): any {
        this.translate.get('corpus.dialog_redirect_message').subscribe((translation: string) => this.dialogRedirectMessage = translation);

        this.homeService.loadHomePage()
            .subscribe((collection: DataCollection) => {
                if (collection.entities.length) {
                    this.homePageData =  collection.entities[0];
                    if (this.homePageData.get('background_header')){
                        this.imgBannerUrl =  this.homePageData ? this.homePageData.get('background_header').uri : '';
                    }
                    if (this.homePageData.get('logo_header')){
                        this.imgLogoUrl =  this.homePageData ? this.homePageData.get('logo_header').uri : '';
                    }
                    this.title =  this.homePageData ? this.homePageData.get('title') : '';
                    this.content =  this.homePageData ? this.homePageData.get('body') : '';
                    this.dataCards = this.homePageData.get('blocks');
                }
            });
    }

    public play(card: any): void {
        if (card && card.granule && !card.granule[0] && card.link_relative_url) {
            if (!this.isValidHttpUrl(card.link_relative_url)){
                this.router.navigate([card.link_relative_url]);
            }else{
                const win = window.open(card.link_relative_url, '_blank');
                win.focus();
            }
        }

        if (card && card.granule && card.granule[0] && card.granule[0].format.label === 'document') {
            const uri = card.granule[0].reference.uri;
            this.downloadDoc(uri);
        }

        if (card && card.granule && card.granule[0] && (card.granule[0].format.label === 'video' || card.granule[0].format.label === 'videoUrl')) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
                titleDialog: card.granule[0].metadatas.title,
            };
            dialogConfig.width = '80%';
            dialogConfig.panelClass = 'resource-modal';

            switch (card.granule[0].format.label) {
                case 'video':
                    dialogConfig.data.bodyDialog = '<video controls>' +
                        '<source src="' + card.granule[0].file.uri + '" type="' + card.granule[0].reference.filemime + '">' +
                        'Your browser does not support HTML5 video.' +
                        '</video>';
                    break;
                case 'videoUrl':
                    dialogConfig.data.bodyDialog = '<div class="videoWrapper">' +
                        '<iframe width="100%" src="' +
                        this.getVideoLinkInfo(card.granule[0].reference.url) +
                        '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>' +
                        '</div>';
                    break;
            }
            this.dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
            this.dialogRef.afterClosed().subscribe(() => {});
        }
    }

    public isValidHttpUrl(string): boolean {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    getVideoLinkInfo(url): string {
            const parsed: Object = urlParser.parse(url);
            if (parsed) {
                return urlParser.create({
                    videoInfo: parsed,
                    format: 'embed'
                });
            }  else if (this.homeService.settings.urlVideoException.some((urlException) => url.includes(urlException))) {
                return url;
            } else {
                return '';
            }

    }


    /***
     * download document and open in a new tab
     * @param path : path of the document
     */
    public downloadDoc(path: string): void {
        window.open(path, '_blank');
    }

}
