import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {take} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {brand} from '../../../app/settings';
import {UserDataEntity} from '@modules/authentication';
import {AutoUnsubscribeTakeUntilClass} from '../../../app/shared/models';
import {Component} from '@angular/core';

@Component({
    selector: 'app-help-lessons-dialog',
    templateUrl: './help-lessons-dialog.component.html',
})
export class HelpLessonsDialogComponent extends AutoUnsubscribeTakeUntilClass {
    public brand = brand;
    public username: string;

    constructor(
        public dialogRef: MatDialogRef<HelpLessonsDialogComponent>,
        protected communicationCenter: CommunicationCenterService,
    ) {
        super();
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData').pipe(take(1))
            .subscribe((userDataEntity: UserDataEntity) => {
                this.username = userDataEntity.get('nickname');
            });
    }
}
