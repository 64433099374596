import {Component} from '@angular/core';
import {UserDataEntity} from '@modules/authentication';
import {combineLatestWith, delayWhen, filter, map, mergeMap, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {combineLatest, from, Observable, of, ReplaySubject, Subject, takeWhile} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AssignmentEntity} from '@modules/assignation/core/models/assignment.entity';
import {Router} from '@angular/router';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {AutoUnsubscribeTakeUntilClass} from "shared/models";

export type AssignationState = TypedDataEntityInterface<{ label: string }>

interface AssignmentToLaunch {
    assignment?: AssignmentEntity;
    stepIndex?: number;
    lessonId?: number;
}

interface SequenceToCheck {
    assignmentId?: number;
    lessonId?: number;
}

interface UserProgress {
    chapters: Chapter[];
}

interface Chapter {
    assignations?: { [key: string]: ShortAssignment };
    chapterId: string;
    progress: number;
    progressChapter?: { [key: string]: Progress }[];
    sequences: number[];
    state: string;
}

interface ShortAssignment {
    id: string;
    type: string;
    assignated_node: string;
}

interface Progress {
    score: number;
    scoreByActivities: { [key: string]: number }[];
}

@Component({
    selector: 'app-launch-button',
    templateUrl: './launch-button.component.html',
    styleUrls: ['./launch-button.component.scss']
})
export class LaunchButtonComponent extends AutoUnsubscribeTakeUntilClass {
    private readonly user$ = this.communicationCenter
        .getRoom('authentication')
        .getSubject<UserDataEntity>('userData')
        .pipe(
            takeUntil(this.unsubscribeInTakeUntil),
            filter(userData => !!userData)
        );
    private readonly autoAssignmentType$ = this.communicationCenter
        .getRoom('assignment')
        .getSubject<{ id: string | number, label: string }[]>('assignationTypes')
        .pipe(
            map(assignationTypes =>
                assignationTypes.find(assignationType => assignationType.label === 'auto')
            ),
            filter(assignationType => !!assignationType),
        );
    private readonly assignatedState$ = this.communicationCenter
        .getRoom('assignments')
        .getSubject<AssignationState[]>('statesList')
        .pipe(
            map(assignatedStates =>
                assignatedStates.find(assignationState => assignationState.get('label') === 'assigned')
            ),
            filter(assignationState => !!assignationState),
        );

    public launching = false;
    private userEducationalLevel: number | string;
    private userConcept: number | string;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
    ) {
        super();
        this.communicationCenter.getRoom('user').getSubject('data').pipe(
            tap((data: {
                educational_level: number | string,
                concept: number | string,
            }) => {
                this.userEducationalLevel =  data.educational_level;
                this.userConcept =  data.concept;
            })
        ).subscribe();

    }

    /**
     * Check through assignements and sequences to find the next lesson to launch.
     */
    public launchNextLesson(): void {
        this.launching = true;
        this.user$.subscribe((user) => {
            this.getNextLesson(user);
        });
    }

    private getNextLesson(user: UserDataEntity) {
        const replaySubjectResults = new ReplaySubject<Observable<AssignmentEntity[]>>(1);
        this.communicationCenter
            .getRoom('assignment')
            .next('loadPaginatedAssignments', {
                filter: {
                    'assignated_user': user.id,
                    'excludeAssignator': true
                },
                onComplete: replaySubjectResults
            });

        replaySubjectResults
            .pipe(
                take(1),
                mergeMap(assignmentsObservable => assignmentsObservable), // Unwrap l'Observable
                mergeMap((assignments ) => this.processAssignments(assignments, user))
            )
            .subscribe((assignmentToLaunch: AssignmentToLaunch) => this.launchAssignment(assignmentToLaunch));
    }

    private processAssignments(assignments: AssignmentEntity[], user: UserDataEntity): Observable<AssignmentToLaunch> {
        const startedAssignments = assignments.filter(assignment => assignment.get('config') !== null);
        const newAssignments = assignments.filter(assignment => assignment.get('config') === null);

        return this.getAssignmentToLaunchFromStartedAssignements$(startedAssignments).pipe(
            switchMap(assignmentToLaunch => assignmentToLaunch ?
                of(assignmentToLaunch) :
                this.handleNewAssignments(newAssignments, user)
            )
        );
    }

    private handleNewAssignments(newAssignments: AssignmentEntity[], user: UserDataEntity): Observable<AssignmentToLaunch> {
        return newAssignments.length > 0 ?
            of({ assignment: newAssignments[0], stepIndex: 0 }) :
            this.getAssignmentToLaunchFromUserProgress$(user);
    }

    private launchAssignment(assignmentToLaunch: AssignmentToLaunch): void {
        if (assignmentToLaunch) {
            this.handleAssignmentToLaunch(assignmentToLaunch);
        } else {
            console.warn('No assignment to launch.');
            this.launching = false;
        }
    }

    private handleAssignmentToLaunch(assignmentToLaunch: AssignmentToLaunch): void {
        if (assignmentToLaunch.assignment && !isNaN(assignmentToLaunch.stepIndex)) {
            this.openLesson(assignmentToLaunch.assignment, assignmentToLaunch.stepIndex);
        } else if (assignmentToLaunch.lessonId) {
            this.createAssignment$(assignmentToLaunch.lessonId).subscribe(assignment => {
                this.openLesson(assignment, 0);
            });
        }
    }




    /**
     * Check through assignements and sequences to find the next lesson to launch if any.
     * @param startedAssignments
     * @private
     */
    private getAssignmentToLaunchFromStartedAssignements$(startedAssignments: AssignmentEntity[]): Observable<AssignmentToLaunch> {
        if (startedAssignments.length > 0) {
            return combineLatest(startedAssignments.map((assignment) => {
                // load lessons of assignment to retrieve their sublessons
                return of(assignment).pipe(
                    combineLatestWith(this.getLesson$(assignment.get('assignated_node').id).pipe(
                        map((lesson) => {
                            return lesson.get('reference').map((reference: {
                                id: string,
                                type: string,
                                title: string
                            }) => reference.id) as string[];
                        })
                    ))
                );
            })).pipe(
                // find the first subLesson not yet played (no score)
                map((assignmentsAndSubLessons) => {
                    for (let [assignment, subLessons] of assignmentsAndSubLessons) {
                        const assignmentToLaunch = this.getAssignmentToLaunchForSubLesson(assignment, subLessons);

                        if (assignmentToLaunch) {
                            return assignmentToLaunch;
                        }
                    }

                    return null;
                })
            );
        } else {
            return of(null);
        }
    }

    /**
     * Check through user progress to find the next lesson to launch if any.
     * @param user
     * @private
     */
    private getAssignmentToLaunchFromUserProgress$(user: UserDataEntity): Observable<AssignmentToLaunch> {
        const userProgressFilter = {
            educationalLevel: user.get('config')?.educational_level || this.userEducationalLevel,
            concept: user.get('config')?.concept || this.userConcept,
        };

        return this.octopusConnect.loadCollection('user-progress', userProgressFilter).pipe(
            take(1),
            map((collection) => collection.entities[0].get('currentUser')),
            mergeMap((userProgress: UserProgress) => {
                if (userProgress) {
                    const sequencesToCheck: SequenceToCheck[] = this.getSequencesToCheckFromUserProgress(userProgress);

                    if (sequencesToCheck.length > 0) {
                        return this.getAssignmentToLaunchFromSequencesToCheck$(sequencesToCheck);
                    }
                }

                return of(null);
            }),
        );
    }

    /**
     * Find the first subLesson not yet played in an assignment.
     * @param assignment
     * @param subLessons
     * @private
     */
    private getAssignmentToLaunchForSubLesson(assignment: AssignmentEntity, subLessons: string[]): AssignmentToLaunch {
        const progress = JSON.parse(assignment.get('config'));
        const unplayedSubLessonIndex = progress ? subLessons.findIndex((subLesson) => !progress[subLesson]) : 0;

        if (unplayedSubLessonIndex > -1) {
            return {
                assignment,
                stepIndex: unplayedSubLessonIndex
            };
        }

        return null;
    }

    /**
     * Build an array of assignments and lessons to check in order to find the next lesson to launch.
     * @param userProgress
     * @private
     */
    private getSequencesToCheckFromUserProgress(userProgress: UserProgress): SequenceToCheck[] {
        const chapters = userProgress.chapters.filter((chapter) => chapter.state === 'pending')
            .concat(userProgress.chapters.filter((chapter) => chapter.state === 'pending2'))
            .concat(userProgress.chapters.filter((chapter) => chapter.state === 'not_started'));

        return chapters.map((chapter) => {
            if (chapter.progressChapter && chapter.assignations) {
                const assignations = Object.keys(chapter.assignations).map((assignationId) => chapter.assignations[assignationId]);
                const orderedAssignations = chapter.sequences.map((sequence) => assignations.find((assignation) => +assignation.assignated_node === +sequence));
                const latestAssignation = assignations[assignations.length - 1];
                const latestAssignationIndex = orderedAssignations.indexOf(latestAssignation);

                for (let i = latestAssignationIndex + 1; i < orderedAssignations.length; i += 1) {
                    if (!orderedAssignations[i]) {
                        return [
                            {assignmentId: +latestAssignation.id},
                            {lessonId: +chapter.sequences[i]}
                        ];
                    }
                }

                return {assignmentId: +latestAssignation.id};
            } else if (chapter.sequences && chapter.sequences.length > 0) {
                return {lessonId: +chapter.sequences[0]};
            }

            return null;
        }).flat().filter((sequence) => sequence !== null);
    }

    /**
     * Check through assignments and lessons to find the next lesson to launch if any.
     * @param sequencesToCheck
     * @private
     */
    private getAssignmentToLaunchFromSequencesToCheck$(sequencesToCheck: SequenceToCheck[]): Observable<AssignmentToLaunch> {
        return new Observable<AssignmentToLaunch>((observer) => {
            // create one subject per sequence to check them sequentially
            const delayers$ = sequencesToCheck.map(() => new Subject<void>());
            // copy the array to mutate both freely and allow to trigger the next sequence to check
            const delayersToTrigger$ = delayers$.slice();

            // transform the array into an observable, but the values ar nexted without delay
            from(sequencesToCheck)
                .pipe(
                    // use one observable per sequence in array to check them sequentially
                    delayWhen(() => delayers$.pop()),
                    mergeMap((sequence) => {
                        if (sequence.assignmentId) {
                            const onAssignmentLoaded$: ReplaySubject<Observable<DataEntity>> = new ReplaySubject<Observable<DataEntity>>();
                            this.communicationCenter
                                .getRoom('assignment')
                                .getSubject('loadAndGetAssignmentById$')
                                .next({ context: sequence.assignmentId, onComplete: onAssignmentLoaded$ })

                            return onAssignmentLoaded$.pipe(
                                take(1),
                                mergeMap(assignmentObservable => assignmentObservable), // Unwrap l'Observable
                                mergeMap((assignment: AssignmentEntity) => combineLatest([of(assignment), this.getLesson$(assignment.get('assignated_node').id)])),
                                map(([assignment, lesson]) => [assignment, lesson.get('reference').map(reference => reference.id)]),
                                map(([assignment, subLessonsId]) => this.getAssignmentToLaunchForSubLesson(assignment, subLessonsId)),
                            );
                        } else if (sequence.lessonId) {
                            return of({lessonId: +sequence.lessonId});
                        }

                        return of(null);
                    }),
                    // continue until we have a non null value and pass that last value to the subscribe callback
                    takeWhile((data) => data === null, true),
                )
                .subscribe((data) => {
                    if (data) {
                        observer.next(data);
                    } else {
                        // trigger the next sequence to check
                        delayersToTrigger$.pop().next();
                    }
                });

            // trigger the first sequence to check
            delayersToTrigger$.pop().next();
        });
    }

    /**
     * Load a lesson from its id.
     * @param lessonId
     * @private
     */
    private getLesson$(lessonId: string | number): Observable<DataEntity> {
        const lessonCbSubject$ = new ReplaySubject<Observable<DataEntity>>();

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId,
                callbackSubject: lessonCbSubject$
            });

        return lessonCbSubject$.pipe(
            switchMap(lesson$ => lesson$),
            take(1),
        );
    }

    /**
     * Start a lesson with its assignment.
     * @param assignment
     * @param stepIndex
     * @private
     */
    private openLesson(assignment, stepIndex: number): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('playLesson', {
                assignment,
                navigateOptions: {
                    exitLessonUrl: this.router.url,
                    startOnStepIndex: stepIndex
                }
            });
    }

    /**
     * Create an assignment for a lesson.
     * @param lessonId
     * @private
     */
    private createAssignment$(lessonId: number): Observable<DataEntity> {
        return combineLatest([
            this.user$,
            this.autoAssignmentType$,
            this.assignatedState$,
        ]).pipe(
            map(([user, autoAssignmentType, assignedAssignmentState]) => ({
                assignated_node: lessonId,
                assignated_user: user.id,
                assignator: user.id,
                dates: {value: 0, value2: 0},
                state_term: assignedAssignmentState.id,
                type_term: autoAssignmentType.id,
            })),
            mergeMap(createAssignmentData => this.communicationCenter
                .getRoom('assignment')
                .getSubject('createSimpleAssignment')
                .pipe(
                    mergeMap((createAssignmentFunction: (data) => Observable<DataEntity>) => createAssignmentFunction(createAssignmentData))
                )
            )
        );
    }
}
