import { trackingID } from "../app/settings";

export function buildApiUrl(brand?: string): string {
    return "https://api.humanum.tralalere.com/";
}

export function buildUrl(brand?: string): string {
    return `https://app.ilove.tralalere.com/`;
}

export function buildNodeUrl(brand?: string): string {
    return ``;
}

export function lrsAuthToken(): string {
    return `ODM2MDIyNGQ0OWVlYmRlYjcwNmFjYjBiYzlmOTczMzI1ZjY4NWM1YjpjZjJhOWE2MWFkMmU4ZDBhODI5ODI3MzEwMDM0MmFkODE4ZTg1YWRi`;
}

export const environment = {
    production: true,
    hmr: false,
    baseApiUrl: buildApiUrl,
    baseAppUrl: buildUrl,
    baseNodeUrl: buildNodeUrl,
    basePlatformPath: `https://lms.ilove.tralalere.com/`,
    baseUrl: buildUrl,
};

export function getTrackingID(trackingId?: string): string {
    return trackingId;
}

export function getTrackingIDMatomo(trackingId?: string): string {
    return '38'; // default
}

/**
 * define type of front making calls to backend
 */
export function getFrontType(): string {
    return "app"; // app or platform
}
