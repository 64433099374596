export const authentication = {
    canAccessToAuthenticationFeatures: false,
    displayLoginLogo: true,
    enableGAR: true,
    enableSSO: false,
    enableCode: true,
    firstConnexionRedirection: {
        trainer: '/groups/list/groups',
    },
    activeChangePasswordStrategy: false,
    rolesAllowedToLogin: [5, 6],
    quickConnect: true,
};