import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector   : 'fuse-mail-confirm',
    templateUrl: './mail-confirm.component.html',
    styleUrls  : ['./mail-confirm.component.scss'],
})
export class FuseMailConfirmComponent implements OnInit
{
  public formTitle: string;
  public contentText: string[];
  public formMessageLinkText: string;
  public formRedirection: string;
  public formRedirectionLink: string;
  public formAction: string;
  public mailConfirmActionButton: boolean;
  public showFormTextRedirection: boolean;

    @Input('data') formData: any;
    action: number;




    constructor(
        private router: Router
    )
    {
    }

    ngOnInit(): void {
      this.formTitle = this.formData.title;
      this.contentText = this.formData.content;
      this.formMessageLinkText = this.formData.textRedirection;
      this.showFormTextRedirection = this.formData.showFormTextRedirection;
      this.formRedirection = this.formData.redirection;
      this.formRedirectionLink = this.formData.redirectionLink;
      this.mailConfirmActionButton = this.formData.mailConfirmActionButton;
      this.formAction = this.formData.action;
    }

    public goOut(): void {
        this.router.navigate(['/lessons/home']);
    }
}
