import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {OctopusConnectService} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import {map, take} from 'rxjs/operators';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-widget-assignments-learners-without-active-assignment',
    templateUrl: './widget-assignments-learners-without-active-assignment.component.html',
    styleUrls: ['./widget-assignments-learners-without-active-assignment.component.scss']
})
export class WidgetAssignmentsLearnersWithoutActiveAssignmentComponent implements OnInit, OnDestroy {
    dataSource = new MatTableDataSource();
    resource = false;
    public displayedColumns: string[] = ['username', 'groups'];
    public isLoading = true;
    @Input('countCallBack') countCallBack: (count: string) => void; // return to parent the number of row currently show

    constructor(private octopusConnect: OctopusConnectService,
                private authService: AuthenticationService) {
    }

    ngOnInit(): any {
        this.getLearnerWithoutAssignment();
    }

    /**
     *  data ar getting here because component role is only showing user without assignment
     *  Because he is only used from dashboard module who doesn't have to know anything about assignment i let
     *  this simple request here. if she had to be used somewhere else we will have to make a class
     *  data doesn't come with good format so we prepare them for being used : { username: string, groups: string[] }[]
     */
    private getLearnerWithoutAssignment(): void {
        const filterOptions: CollectionOptionsInterface = {
            filter: {
                field_created_by: this.authService.loggedUser.id,
                roles: 6, // only learners
                learnerWithoutActiveAssignment: true // learner without any assignment opened now
            },
        };

        this.octopusConnect.paginatedLoadCollection('user_search', filterOptions)
            .collectionObservable
            .pipe(
                map(collection => collection.entities.map(
                    entity => {
                        return {
                            username: entity.get('name'),
                            // groups or workgroups are return we only need groups (Type === Class)
                            groups: entity.get('og_user_node')
                                .filter(node => node.type === 'Class')
                                .map(node => node.title)
                        };
                    }
                )),
                take(1)
            )
            .subscribe((res: { username: string, groups: string[] }[]) => {
                this.dataSource.data = res;
                    this.countCallBack(this.dataSource.data.length.toString());
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                console.error(' error getting learners without assignment' + error);
            });
    }

    ngOnDestroy(): void {
    }

    get empty(): boolean {
        if (this.dataSource.data.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    public get align(): string {
        if (this.resource) {
            return 'start center';
        }
        return 'center center';
    }
}
