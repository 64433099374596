import {Component, inject, Input, OnInit} from '@angular/core';
import {Button} from "shared/ui-components/button-list-card/button-list-card.component";
import {AssignmentEntity} from "@modules/assignation/core/models/assignment.entity";
import {Observable, ReplaySubject, shareReplay} from "rxjs";
import {map, switchMap, take} from "rxjs/operators";
import {TypedDataEntityInterface} from "shared/models/octopus-connect";
import {GranuleEntityAttributesInterface} from "shared/models/granule";
import {Router} from "@angular/router";
import {CommunicationCenterService} from "@modules/communication-center";

type LimitedLessonReferenceAttributes = { id: string, title: string, type: string }
type LimitedLessonEntity = TypedDataEntityInterface<GranuleEntityAttributesInterface<LimitedLessonReferenceAttributes[]>>

@Component({
    selector: 'app-assignment-as-button-list',
    templateUrl: './assignment-as-button-list.component.html',
    styleUrls: ['./assignment-as-button-list.component.scss']
})
export class AssignmentAsButtonListComponent implements OnInit {
    @Input() public assignment: AssignmentEntity;
    public buttons$: Observable<Button[]>;
    public title$: Observable<string | null>;
    public lesson$: Observable<LimitedLessonEntity>;

    private communicationCenter = inject(CommunicationCenterService);
    private router = inject(Router);
    private lessons$Cache = new Map<number, Observable<LimitedLessonEntity>>();

    public ngOnInit() {
        this.lesson$ = this.getLesson$(this.assignment.get('assignated_node')?.id).pipe(shareReplay(1));
        this.title$ = this.getTitle();
        this.buttons$ = this.getSubLessonsAsButtons$(this.assignment);
    }

    public hasSubLesson(lesson: LimitedLessonEntity) {
        return lesson.get('reference').some(ref => ref.type === 'lesson');
    }

    private getSubLessonsAsButtons$(assignment: AssignmentEntity) {
        return this.lesson$.pipe(
            map(lesson => {
                if (this.hasSubLesson(lesson) === false) {
                    return [{
                        title: lesson.attributes.metadatas.title,
                        action: ($event: Event) => {
                            $event.stopPropagation();
                            this.openLesson(assignment, 0);
                        },
                        score: this.getLessonProgress(assignment, lesson)
                    }]
                } else {
                    return lesson.get('reference')
                        .map((child, index) => ({
                            title: child.title,
                            action: ($event: Event) => {
                                $event.stopPropagation();
                                this.openLesson(assignment, index);
                            },
                            score: this.getLessonProgress(assignment, child)
                        }))
                }
            })
        );
    }


    /**
     * Récupère une leçon en cache ou la demande au serveur
     * On a besoin du cache parce qu'aujourd'hui il y a un bug dans octopus-connect
     * Si l'on demande deux fois la même leçon, le premier appelé risque de ne pas fonctionner
     */
    private getLesson$(lessonId: string | number) {
        const lesson$Cache = this.lessons$Cache.get(+lessonId);
        if (!!lesson$Cache) {
            return lesson$Cache;
        }

        // attention, si on ne met pas un replay subject, on n'aura plus de donnée pour le return
        const lessonCbSubject$ = new ReplaySubject<Observable<LimitedLessonEntity>>();

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId,
                callbackSubject: lessonCbSubject$
            });

        const toCacheObs = lessonCbSubject$.pipe(
            switchMap(lesson$ => lesson$),
            take(1)
        );

        this.lessons$Cache.set(+lessonId, toCacheObs);
        return toCacheObs;
    }

    private openLesson(assignment, stepIndex: number) {
        this.communicationCenter
            .getRoom('lessons')
            .next('playLesson', {
                assignment,
                navigateOptions: {
                    exitLessonUrl: this.router.url,
                    startOnStepIndex: stepIndex
                }
            });
    }

    /**
     * Retrieve the score as progress for a given sub lesson from an assignment
     */
    private getLessonProgress(assignment: AssignmentEntity, child: LimitedLessonReferenceAttributes | LimitedLessonEntity) {
        let progress = 0;

        if (assignment) {
            const config = JSON.parse(assignment.get('config'));

            if (config) {
                if (config[child.id]) {
                    progress = config[child.id].score;
                }
            }
        }

        return progress;
    }

    private getTitle() {
        return this.lesson$.pipe(map(lesson => {
            if (this.hasSubLesson(lesson)) {
                return this.assignment.get('assignated_node')?.title;
            }

            return null;
        }));
    }
}
