
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {brand, customHomePageBanner} from 'app/settings';
import {modulesSettings} from '../../../app/settings';
import {CommunicationCenterService} from '@modules/communication-center';

const settingsStructure: ModelSchema = new ModelSchema(({
    secureAlias: Structures.array([])
}));
@Injectable({
    providedIn: 'root'
})
export class BasicPageService {

    public settings: {[key: string]: any};
    public brand: string = brand;
    public isCustomBanner: boolean = customHomePageBanner;
    public userData: DataEntity

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.basicPage);
    }

    public loadPage(alias: string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('pages', alias).pipe(take(1));
    }

    isUserLogged(): any {
            const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

            const obs: Observable<DataEntity> = this.octopusConnect.authenticated('http');

            obs.pipe(take(1)).subscribe((userData: DataEntity) => {
                subject.next(true);
            }, () => {
                subject.next(false);
                this.communicationCenter
                    .getRoom('authentication')
                    .next('goToLogin', true);
            });

            return subject;
    }
}
