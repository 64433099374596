import {map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {captcha, defaultApiURL, defaultRoute, modulesSettings} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {CommunicationCenterService} from '@modules/communication-center';
import {HttpClient} from '@angular/common/http';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';

const settingsStructure: ModelSchema = new ModelSchema({
    selfSignup: Structures.boolean(true),
    signup: Structures.boolean(true),
    passwordPolicy: Structures.object(null),
    validateEmailStrategyActivated: Structures.boolean(false),
    showFormTextRedirection: Structures.boolean(false),
    mailConfirmActionButton: Structures.boolean(false),
});

@Injectable()
export class AccountManagementProviderService {
    userIsLogged$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    loggedUser: UserDataEntity;

    // TODO créer un service config
    public settings: { [key: string]: any };
    captcha: string = captcha;

    constructor(
        private router: Router,
        private connector: OctopusConnectService,
        private authenticationService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private http: HttpClient
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject<UserDataEntity>('userData')
            .subscribe(user => {
                if (user) {
                    this.loggedUser = user;
                    this.postAuthentication();
                    this.connector.createEntity('authenticated', {
                        id: this.loggedUser.id,
                        myType: 'authenticated'
                    }).pipe(take(1));
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('account-management')
            .getSubject('refreshUser')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.getCurrentUser();
                }
            });
        this.communicationCenter
            .getRoom('authentication').next('userAccessToken', this.userAccessToken);
        this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
    }

    get userAccessToken(): any {
        return JSON.parse(localStorage.getItem('http_accessToken'));
    }

    /**
     * return default route instance
     * @returns {string}
     */
    public get defaultRoute(): string {
        return defaultRoute;
    }

    createUser(user, callback, callbackError): void {
        this.connector.createEntity('user-registration', user).subscribe((userData) => {
            if (this.settings.validateEmailStrategyActivated) {
                callback();
            } else {
                this.loggedUser = <UserDataEntity>userData;
                this.autoConnectUser(user, callback);
            }
        }, (error: Object) => {
            if (callbackError) {
                callbackError(error);
            } else {
                console.error(error);
            }
        });
    }

    sendMessage(message, callback): void {
        this.connector.createEntity('contact-message', message).subscribe(() => {
            callback();
        });
    }

    getCurrentUser(): Observable<boolean> {

        const userData$ = <Observable<UserDataEntity>>this.connector.authenticated('http');

        userData$.subscribe({
            next: (userData) => {
                this.loggedUser = userData;
                this.userIsLogged$.next(true);
            }, error: (_err) => {
                this.userIsLogged$.next(false);
            }
        });

        return this.userIsLogged$;
    }

    /**
     * Save user data
     * @param user data
     * @param callback with true if success, else false
     */
    public editUser(user, callback): void {
        if (user.label) {
            this.loggedUser.set('label', user.label);
        }
        if (user.email) {
            this.loggedUser.set('email', user.email);
        }
        if (user.institution) {
            this.loggedUser.set('institution', user.institution);
        }
        if (user.you_are) {
            this.loggedUser.set('you_are', user.you_are);
        }
        if (user.find_us) {
            this.loggedUser.set('find_us', user.find_us);
        }
        if (user.level) {
            this.loggedUser.set('level', user.level);
        }
        if (user.region) {
            this.loggedUser.set('region', user.region);
        }
        if (user.newsletter) {
            this.loggedUser.set('newsletter', user.newsletter);
        }
        if (user.picture) {
            this.loggedUser.set('_picture', user.picture);
        }
        if (user.config) {
            this.loggedUser.set('config', user.config);
        }
        // this.loggedUser.set('role', user.role );
        if (user.password) {
            this.loggedUser.set('password', user.password);
        }
        this.loggedUser.save().subscribe({
            next: (userEdited) => {
                this.loggedUser = userEdited;
                this.emitUser();
                callback(true);
            }, error: _error => callback(false)
        });
    }

    private emitUser() {
        this.communicationCenter
            .getRoom('authentication')
            .next('userData', this.loggedUser)
    }

    editUserValidateEmail(): void {
        if (this.loggedUser) {
            this.loggedUser.set('email_status', true);
            this.loggedUser.save();
        } else {
            this.router.navigate(['/']);
        }
    }

    /**
     * Remove current logged user
     */
    public deleteCurrentLoggedUser(): Observable<boolean> {
        return this.loggedUser.remove();
    }

    public verifyToken(token: string): Observable<any> {
        switch (this.captcha) {
            case 'recaptcha':
                return this.http
                    .post<any>(defaultApiURL + 'api/recaptchav3', {'response': token});
                break;
            case 'friendlyCaptcha':
                return this.http
                    .post<any>(defaultApiURL + 'api/friendlycaptcha', {'solution': token});
                break;
            default:
                console.log('no captcha in setting');
        }
    }

    /**
     * Obtain list of educational levels
     */
    public getEducationalLevels(): Observable<DataEntity[]> {
        return this.connector.loadCollection('educational_level').pipe(map(collection => collection.entities));
    }

    private postLogout(): void {

    }

    private postAuthentication(): void {
        this.getCurrentUser();
    }

    /**
     * auto connect user after create account without verifying mail before
     * old process Marque blanche common process not use it anymore
     * @param user : user data
     * @param callback callback : pass screen to info saying email confirmation was send
     * @private
     */
    private autoConnectUser(user: any, callback: any): void {
        let user_email = user['email'];
        if (!user_email) {
            user_email = user['label'];
        }
        this.authenticationService.authenticateIn('http', user_email, user['password']).subscribe((userData) => {
            this.loggedUser = userData;
            callback();
        }, err => {
            console.log(err);
        });
    }
}
