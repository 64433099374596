import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[appAddClassInRegardOfTextHeight]'
})
/**
 * this directive is used to pass a class to element in regard of lenght of text inside it
 * first it take span text and if not exist it will take text present
 * it's for example to change on a button background img in regard of the number of line needed for text
 * we pass in input the number of letter possible by line to determine the class to add l1 l2 l3 l1= one row l2 =2 row etc
 * example of use will return l2 = two row needed because 18 letter and only 10 by line possible here:
 *  <button [letterByLine]="10" appAddClassInRegardOfTextSize >
 <mat-icon aria-hidden="true" svgIcon="lessonCardIcon" ></mat-icon>
 <span>activities explore</span>
 </button>
 * you can also use it for another configuration without button and span passing value elementToTarget
 * example : (will push class l3)
 * <div [letterByLine]="10" [elementToTarget]="'li'" appAddClassInRegardOfTextSize>
        <h1>test de longueur de nom</h1>
    </div>
 */
export class AddClassInRegardOfTextHeightDirective implements OnInit, AfterViewInit, OnChanges {
    @Input() heightByLine = 30; // number of letter by line before we need a new line default value must be overide for more precision
    @Input() elementToTarget = 'span'; // optionnal input if element you want to target is not a span
    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.injectClassInRegardOfTextHeight();
    }

    ngOnChanges(): void {
        this.injectClassInRegardOfTextHeight();
    }

    /**
     * inject class in regard of height text lineText1 one row lineText2 two row lineText3 three row etc...
     * css will be adjust to increase height, change img etc.. in regard of this class
     * @private
     */
    private injectClassInRegardOfTextHeight(): void {
        const monSpan = <HTMLElement>this.el.nativeElement.querySelector(this.elementToTarget);
        let heightText = 0;
        // use span if exist if not use button text
        if (monSpan) {
            heightText = monSpan.clientHeight;
        } else {
            heightText = this.el.nativeElement.clientHeight;
        }
        // calc the number of line needed and inject class
        if (heightText > this.heightByLine) {
            this.renderer.addClass(this.el.nativeElement, 'lineText' + Math.ceil(heightText / this.heightByLine));
        } else {
            this.renderer.addClass(this.el.nativeElement, 'lineText1');
        }
    }
}
