import {Component, Input} from '@angular/core';
import {MainMenuElementGenericBase} from '../../models/main-menu-element-generic.base';
import * as _ from 'lodash';
import {MainMenuElementBatchInterface} from '../../models/main-menu-element-batch.interface';
import {MainMenuElementLinkInterface} from '../../models/main-menu-element-link.interface';

@Component({
    selector: 'app-main-menu-element-generic',
    templateUrl: './main-menu-element-generic.component.html'
})
export class MainMenuElementGenericComponent {

    @Input() public element: MainMenuElementGenericBase;
    @Input() public index: number;

    public isBatch(): boolean {
        return _.has(this.element, 'children');
    }

    public toBatch(): MainMenuElementBatchInterface {
        return this.element as MainMenuElementBatchInterface;
    }

    public toLink(): MainMenuElementLinkInterface {
        return this.element as MainMenuElementLinkInterface;
    }

    public isExternalUrl(): boolean {
        return _.has(this.element, 'externalUrl');
    }

    public isOpenInNewTab(): boolean {
        return _.has(this.element, 'openInNewTab');
    }
}
