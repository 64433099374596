import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GamificationService } from '../gamification.service';
import { Creature } from '../definitions';

@Component({
    selector: 'gamification',
    templateUrl: './gamification.component.html',
    styleUrls: ['./gamification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GamificationComponent implements OnInit {

    public activeTab: 'gallery' | 'animals' = 'gallery';
    selectedTab = 0;
    selectedCreature: Creature;

    constructor(
        private gamification: GamificationService
    ) { }

    ngOnInit(): void {
        this.gamification.startPersonalisation();
        this.gamification.loadBadges();
    }

    get userPoints(): any {
        return this.gamification.userPoints;
    }

    switchTab(name: 'gallery' | 'animals'): void {
        this.activeTab = name;
    }

    get isReady(): boolean {
        return this.gamification.badges.creaturesStatus === 'loaded' && this.gamification.badges.universesStatus === 'loaded';
    }

    openCreature(creature: Creature): void {
        if (creature.creature.attributes.unLocked === true) {
            this.selectedCreature = creature;
            this.selectedTab = 1;
        }
    }
}



