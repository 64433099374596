import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {map, take} from 'rxjs/operators';
import {DataCollection, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';

const ID_TYPE_CLASS = 2;
/**
 * component that permit the current user to join a class or leave a class
 * for test code class NJ435 and 59331 can be used
 */
@Component({
    selector: 'app-join-class',
    templateUrl: './join-class.component.html'
})
export class JoinClassComponent implements OnInit {
    classCode = new UntypedFormControl('');
    userClassList: IUserClassList[] = [];
    errorMessage = '';

    constructor(private profileService: ProfileService,
                private octopusConnect: OctopusConnectService) {
    }

    ngOnInit(): void {
        this.initCLassCodeListOfUser();
    }

    /**
     * prepare data to store it in a friendly format (code class id and label) for the different class of user
     * @private
     */
    private initCLassCodeListOfUser(): void {
        const idsGroup: (string|number)[] | number[] = this.profileService.userInformation.get('groups') || [];
        if (idsGroup.length > 0) {
            this.loadGroupsById(idsGroup.map(id => +id)).subscribe(groupsInfo => {
                this.userClassList = groupsInfo;
            });
        }
    }

    /**
     * load the class by id and format to keep code id and label
     * @param idsGroup array of id of group in number format
     * @private
     */
    private loadGroupsById(idsGroup: number[]): Observable<IUserClassList[]> {
        const filter = {id: idsGroup, type: ID_TYPE_CLASS};
        const data = this.octopusConnect.loadCollection('groups', filter) as Observable<DataCollection>;
        return data.pipe(
            map(g => g.entities
                .map((group: any) => {
                    return {
                        id: group.id,
                        label: group.get('label'),
                        code: group.get('code')
                    };
                })),
        );
    }

    /**
     * load the groups by code class data are formated and contain id label and code
     * @param codeClass id of group in string format
     * @private
     */
    private loadGroupsByCodeClass(codeClass: string): Observable<IUserClassList[]> {
        const filter = {code: codeClass};
        const data = this.octopusConnect.loadCollection('groups', filter) as Observable<DataCollection>;
        return data.pipe(
            map(g => g.entities
                .map((group: any) => {
                    return {
                        id: group.id,
                        label: group.get('label'),
                        code: group.get('code')
                    };
                }))
        );
    }

    /**
     * join a new class using a code class
     */
    public joinClass(): void {
        this.errorMessage = '';
        // check if codeClass is not already added
        if (this.userClassList.filter(g => g.code === this.classCode.value).length > 0) {
            this.errorMessage = 'account-management.class-code-already-added';
            return;
        }
        // load group id and label and code class corresponding to code class use
        this.loadGroupsByCodeClass(this.classCode.value)
            .pipe(take(1))
            .subscribe(groupInfo => {
                if (groupInfo.length > 0) {
                    let newList = this.userClassList.map(g => g.id);
                    newList.push(groupInfo[0].id);
                    this.profileService.userInformation.set('groups', newList);
                    this.profileService.userInformation.save()
                        .pipe(take(1))
                        .subscribe(res => {
                            // on success add new code class to list
                            this.userClassList.push({id: groupInfo[0].id, code: groupInfo[0].code, label: groupInfo[0].label});
                            this.classCode.setValue('');
                        }, error => {
                            this.errorMessage = 'account-management.join-class-error';
                        });
                } else {
                    this.errorMessage = 'account-management.join-class-error-code-not-exist';
                }
            }, error => {
                this.errorMessage = 'account-management.join-class-error';
            });
    }

    /*
     * remove link with a class
     */
    public removeClass(idClass): void {
        this.errorMessage = '';
        const idClassList = this.userClassList.filter(g => g.id !== idClass).map(g => g.id);
        // update user with new list of class if success show error if update failed
        this.profileService.userInformation.set('groups', idClassList);
        this.profileService.userInformation.save()
            .pipe(take(1))
            .subscribe(res => {
                // update list after success of remove
                this.userClassList = this.userClassList.filter(g => g.id !== idClass);
            }, error => {
                this.errorMessage = 'account-management.remove-class-error';
            });
    }
}

export interface IUserClassList {
    id: number;
    code: string;
    label: string;
}
