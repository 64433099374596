import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@modules/authentication';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';

@Component({
    selector: 'fuse-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class FuseProfileComponent implements OnInit, AfterViewInit, OnDestroy {
    public navLinks: { path: string, keyTranslate: string }[];
    public settingsLicensing: { [key: string]: any } = {};

    private subscribeSettings: Subscription;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private authenticationService: AuthenticationService,
        private profileSvc: ProfileService
    ) {
        this.subscribeSettings = this.communicationCenter.getRoom('licenses').getSubject('settings').subscribe((data) => {
            this.settingsLicensing = data;
        });
    }

    ngOnInit(): void {
        this.navLinks = [
            {path: 'show-profile', keyTranslate: 'account-management.my_data'},
        ];
        if (this.settingsLicensing['visible'] && !this.authenticationService.isManager()) {
            this.navLinks.push({path: 'licensing', keyTranslate: 'account-management.profile.licensing.title'});
        }
        if (this.profileSvc.getCanLinkChild()) {
            this.navLinks.push({path: 'link-child', keyTranslate: 'link_child.title'});
        }
        if (this.profileSvc.getCanUnlinkParent()) {
            this.navLinks.push({path: 'unlink-parent', keyTranslate: 'unlink_parent.title'});
        }
    }

    ngAfterViewInit(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'position-top-lower-for-content');
    }

    ngOnDestroy(): void {
        this.subscribeSettings.unsubscribe();
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'position-top-lower-for-content');
    }
}
