import {Component, Inject, ElementRef, ViewChild} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {distinctUntilChanged, tap, take, takeUntil} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
    EducationalLevelEntity,
    EducationalLevelService
} from '@modules/activities/core/services/educational-level.service';
import {ConceptsService} from '@modules/activities/core/services/concepts.service';
import {brand} from '../../../app/settings';
import {UserDataEntity} from "@modules/authentication";
import {combineLatest} from "rxjs";
import {AutoUnsubscribeTakeUntilClass, ConceptEntity} from "../../../app/shared/models";

@Component({
    selector: 'fuse-selection-dialog',
    templateUrl: './selection-dialog.component.html',
    styleUrls: ['./selection-dialog.component.scss']
})
export class FuseSelectionDialogComponent extends AutoUnsubscribeTakeUntilClass {
    public brand = brand;
    public username: string;
    @ViewChild('inputToFocus') inputToFocus: ElementRef;
    selectionForm: UntypedFormGroup;
    educational_levels: EducationalLevelEntity[];
    filteredConcepts: ConceptEntity[];

    current_educational_level: string;
    current_concept: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<FuseSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: { educational_level: string; concept: string },
        protected communicationCenter: CommunicationCenterService,
        private educationalLevelService: EducationalLevelService,
        private conceptsService: ConceptsService,
    ) {
        super();
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData').pipe(take(1))
            .subscribe((userDataEntity: UserDataEntity) => {
                this.username = userDataEntity.get('nickname');
            });

        this.current_educational_level = data.educational_level;
        this.current_concept = data.concept;

    }

    ngOnInit(): void {
        combineLatest([
            this.initLevels(),
            this.initConcepts()
        ]).pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe()

        //init form
        this.selectionForm = this.formBuilder.group({
            educational_level: ['', Validators.required],
            concept: ['', Validators.required],
        });

        // set default value
        this.selectionForm.controls.educational_level.setValue(this.current_educational_level);
        this.selectionForm.controls.concept.setValue(this.current_concept);

        combineLatest([
            this.conceptsService.getConcepts(),
            this.selectionForm.controls.educational_level.valueChanges
        ])
            .pipe(
                distinctUntilChanged(),
                tap(([conceptCollection, levelId]) => {
                    this.filteredConcepts = conceptCollection.entities.filter(concept => concept.attributes.levels ? concept.attributes.levels.includes(levelId) : false)
                })
            ).subscribe();

    }

    selectionFormSubmit(): void {
        const concept = this.selectionForm.value.concept;
        const educational_level = this.selectionForm.value.educational_level;
        const selected = {
            educational_level: this.educational_levels.find(level => level.id === educational_level),
            concept: this.filteredConcepts.find(loopConcept => loopConcept.id === concept),
        };
        this.dialogRef.close(selected);
    }

    private initLevels() {
        return this.educationalLevelService.getEducationalLevels()
            .pipe(
                tap(collection => this.educational_levels = collection.entities)
            )
    }

    private initConcepts() {
        return this.conceptsService.getConcepts()
            .pipe(
                tap(collection => this.filteredConcepts = collection.entities.filter(concept => concept.attributes.levels ? concept.attributes.levels.includes(this.current_educational_level) : false)))
    }
}
