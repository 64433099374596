import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ProfileService} from '../../profile.service';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {TralaTranslationLoaderService} from '../../../../../../trala-translation-loader.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {langs} from '../../../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {
    ProfileDeleteConfirmModalComponent
} from '@modules/account-management/core/profile/profile-delete-confirm-modal/profile-delete-confirm-modal.component';
import {isTypeOf} from "shared/utils";

@Component({
    selector: 'fuse-profile-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FuseProfileAboutComponent implements OnInit {
    userInformation: DataEntity;
    defaultUserInformation: FormProfile;
    findUsValue: string;
    regionValue: string;
    levelValue: string;
    youAreValue: string;
    studentCode: string;
    public learnerCanEditMail: boolean;

    constructor(
        public authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private profileProvider: ProfileService,
        private translationLoader: TralaTranslationLoaderService,
        private octopusConnect: OctopusConnectService
    ) {
        this.defaultUserInformation = new FormProfile({}, this.octopusConnect);
        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.translationLoader.loadTranslations(...defaultLang);
    }

    ngOnInit(): any {
        this.learnerCanEditMail = this.profileProvider.learnerCanEditMail();

        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {

            if (isTypeOf<DataEntity>(userInformation, 'get'))
                this.userInformation = userInformation;

            this.findUsValue = this.getValue(this.profileProvider.getFindUsValues(), this.userInformation.get('find_us'));

            if (this.userInformation.get('region') && this.userInformation.get('region').hasOwnProperty('label')) {
                this.regionValue = this.userInformation.get('region').label;
            }

            if (this.userInformation.get('level') && this.userInformation.get('level').hasOwnProperty('label')) {
                this.levelValue = this.userInformation.get('level').label;
            }

            if (this.userInformation.get('codeid')) {
                const studentCodeArray = this.userInformation.get('codeid').split('');
                studentCodeArray.splice(4, 0, '-');
                studentCodeArray.splice(9, 0, '-');
                this.studentCode = studentCodeArray.join('');
            }
            this.youAreValue = this.getValue(this.profileProvider.getYouAreValues(), this.userInformation.get('you_are'));
        });
    }

    setMode(mode: boolean): void {
        this.profileProvider.editMode = mode;
    }

    getTranslate(): string {
        if (this.userInformation) {
            if (this.userInformation.get('newsletter')) {
                return 'generic.yes';
            } else {
                return 'generic.no';
            }
        }

        return 'generic.to-complete';
    }

    ifEmptyValue(val): string {
        if (this.userInformation && this.userInformation.get(val)) {
            return this.userInformation.get(val);
        }
        return 'generic.to-complete';
    }

    displayField(name: string): boolean {
        return this.profileProvider.getFields().some(f => f.label === name);
    }

    public deleteCurrentAccount(): void {
        this.dialog.open(ProfileDeleteConfirmModalComponent);

    }

    public canDeleteCurrentAccount(): boolean {
        return this.profileProvider.getCanSelfDelete().includes(this.authenticationService.accessLevel);
    }

    private getValue(field, value): string {
        const found = field.find((find) => find === value);
        return found ? found : 'generic.to-complete';
    }
}
