import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicMenuService} from 'app/navigation/services/dynamic-menu.service';
import {MainMenuElementGenericBase} from '../../models/main-menu-element-generic.base';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html'
})
export class MainMenuComponent implements OnInit, OnDestroy {
    public menu: MainMenuElementGenericBase[] = [];
    public menuTop: MainMenuElementGenericBase[] = [];
    public menuBottom: MainMenuElementGenericBase[] = [];
    private unsubscribeTakeUntil: Subject<void> = new Subject<void>();

    constructor(private dynamicMenuService: DynamicMenuService, private communicationCenter: CommunicationCenterService) {
    }

    ngOnInit(): void {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }

    private postAuthentication(): void {
        this.dynamicMenuService.refreshMenuSubject.pipe(
            takeUntil(this.unsubscribeTakeUntil),
            tap((menu: MainMenuElementGenericBase[]) => this.menu.length > 0 ? false : this.separateMenu(menu) )
        ).subscribe();
    }

    private postLogout(): void {
        this.dynamicMenuService.refreshMenu(false);
    }

    private separateMenu(menu) {
        this.menu = menu;
        this.menu.forEach((element) => {
            if (element.externalUrl === true){
                this.menuBottom.push(element);
            }else{
                this.menuTop.push(element);
            }
        });
    }

}
