import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-shared-video',
  templateUrl: './shared-video.component.html',
  styleUrls: ['./shared-video.component.scss']
})
export class SharedVideoComponent implements OnInit, OnChanges {
    @Input() controlsList: string = undefined;
    @Input() oncontextmenu: string = undefined;
    @Input() controls = false;
    @Input() muted: string = undefined;
    @Input() public video!: {
        uri: string;
        filemime: string;
        subtitles: {
            [key: string]: string;
        }
    };
    private source!: string;
    private filemime: string;
    private subtitles: { label: string; lang: string; url: string | SafeResourceUrl }[] = [];
    private initialMutedState: boolean;

    @ViewChild('myVideo') private videoElement: ElementRef;

  constructor(
      public sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(): void {
        this.source = this.video.uri;
        this.filemime = this.video.filemime;
        if (this.video?.subtitles) {
            this.subtitles = Object.keys(this.video.subtitles).map(lang => {
                return {
                    lang,
                    url: this.sanitizer.bypassSecurityTrustResourceUrl(this.video.subtitles[lang]),
                    label: 'video.subtitles_label_' + lang,
                };
            });
        }
  }

    ngAfterViewInit(): void {
        const video = this.videoElement.nativeElement as HTMLVideoElement;
        this.initialMutedState = video.muted;
        video.addEventListener("volumechange", () => {
            // block mute button effect if activities is set muted
            if( video.muted === false && this.initialMutedState === true ) {
                video.muted = this.initialMutedState;
            }
        });
    }

    public onContextMenu(event: MouseEvent): boolean {
        event.preventDefault();
        return false;
    }

    protected readonly undefined = undefined;
}
