import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Component} from '@angular/core';
import {brand} from '../../../app/settings';

@Component({
    selector: 'app-help-dictionary-dialog',
    templateUrl: './help-dictionary-dialog.component.html'
})
export class HelpDictionaryDialogComponent {
    public brand = brand;
    public titles = ['generic.dictionary-help-title1', 'generic.dictionary-help-title2'];
    public currentStep = 0;

    constructor(
        public dialogRef: MatDialogRef<HelpDictionaryDialogComponent>,
    ) {
    }

    next(): void {
        if (this.currentStep === 0) {
            this.currentStep = 1;
        } else {
            this.dialogRef.close();
        }
    }
}
