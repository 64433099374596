import {Component, Input} from '@angular/core';

export type Button = {title: string, action: ($event: Event) => void, score?: number}

@Component({
  selector: 'app-button-list-card',
  templateUrl: './button-list-card.component.html',
  styleUrls: ['./button-list-card.component.scss']
})
export class ButtonListCardComponent {
    @Input() title: string;
    @Input() showState: boolean = false;
    @Input() buttons: Button[]
}
