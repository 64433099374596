import {NgModule} from '@angular/core';
import {SharedMaterialModule} from './shared-material.module';
import {SharedTranslateModule} from './shared-translate.module';
import {FuseConfirmDialogModule} from 'fuse-core/components/confirm-dialog/confirm-dialog.module';
import {DynamicModule} from '@fuse/components/dynamic/dynamic.module';
import {BasicPageModule} from 'fuse-core/components/basic-page/basic-page.module';
import {CardModule} from '@fuse/components/card/card.module';
import {GettingStartedModule} from 'fuse-core/components/getting-started/getting-started.module';
import {SelectCheckAllComponent} from './ui-components/select-check-all/select-check-all.component';
import {CollectionModule} from 'fuse-core/components/collection/collection.module';
import {ActivtyCardComponent} from './ui-components/activty-card/activty-card.component';
import {CommonModule, NgClass} from '@angular/common';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {FuseSelectionDialogModule} from 'fuse-core/components/selection-dialog/selection-dialog.module';
import {ButtonListCardComponent} from './ui-components/button-list-card/button-list-card.component';
import {ScoreIndicatorComponent} from './ui-components/score-indicator/score-indicator.component';
import {LaunchButtonComponent} from "shared/ui-components/launch-button/launch-button.component";
import {FormDialogComponent} from "fuse-core/components/form-dialog/form-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        CardModule,
        DynamicModule,
        FuseConfirmDialogModule,
        FuseSelectionDialogModule,
        BasicPageModule,
        CollectionModule,
        GettingStartedModule,
        SharedMaterialModule,
        SharedTranslateModule,
        NgClass,
        ClipboardModule
    ],
    declarations: [
        SelectCheckAllComponent,
        ActivtyCardComponent,
        ButtonListCardComponent,
        ScoreIndicatorComponent,
        LaunchButtonComponent,
        FormDialogComponent
    ],
    exports: [
        CardModule,
        DynamicModule,
        FuseConfirmDialogModule,
        FuseSelectionDialogModule,
        BasicPageModule,
        CollectionModule,
        GettingStartedModule,
        SharedMaterialModule,
        SharedTranslateModule,
        ClipboardModule,
        SelectCheckAllComponent,
        ActivtyCardComponent,
        ButtonListCardComponent,
        LaunchButtonComponent
    ]
})
export class SharedModule {}
