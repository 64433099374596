import {Injectable} from '@angular/core';
import {MainMenuOpeningModeEnum} from '../models/main-menu-opening-mode.enum';
import {DynamicMenuSettingsService} from './dynamic-menu-settings.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {MainMenuElementGenericBase} from '../models/main-menu-element-generic.base';
import {OnboardingService} from '../../shared/onboarding/onboarding.service';
import {filter, take} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DynamicMenuService {

    public currentUserMenu: MainMenuElementGenericBase[];
    public isOpen = true;
    public mode: MainMenuOpeningModeEnum = MainMenuOpeningModeEnum.side;
    public refreshMenuSubject: ReplaySubject<MainMenuElementGenericBase[]> = new ReplaySubject<MainMenuElementGenericBase[]>(1);
    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private dynamicNavigationSettingsService: DynamicMenuSettingsService,
        private onBoardingService: OnboardingService
    ) {
        this.mode = dynamicNavigationSettingsService.getDefaultMode();
        this.isOpen = dynamicNavigationSettingsService.getDefaultOpenedState();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private postAuthentication(): void {
        //TODO: le systeme de license class/institution/free n'est plus utilisé et dans le cas de l'app ne servait qu'a afficher un btn retour vers la plateforme
        // maintenant on veut un btn retour vers la plateforme si on est simplement authentifié
        /*if (this.dynamicNavigationSettingsService.getExternalLinkWithLicense()?.checkIfUserHasFreeLicense) {
            this.onBoardingService
                .isUserHasFreeLicence$()
                .pipe(
                    take(1),
                    filter((isUserHasFreeLicense: boolean) => isUserHasFreeLicense)
                )
                .subscribe((isUserHasFreeLicense: boolean) => this.refreshMenu(isUserHasFreeLicense));
        } else {
            this.refreshMenu();
        }*/
        this.refreshMenu(true);
    }

    private postLogout(): void {
        this.refreshMenu();
    }

    public refreshMenu(isUserHasFreeLicense = false): void {
        this.refreshMenuSubject.next(isUserHasFreeLicense ?
            [...this.dynamicNavigationSettingsService.getMenuForRole(this.authService.accessLevel),
                this.dynamicNavigationSettingsService?.getExternalLinkWithLicense()?.externalLink]
            : this.dynamicNavigationSettingsService.getMenuForRole(this.authService.accessLevel));
    }
}

