import {AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appAddClassIfContainsPicture]'
})
/**
 * this directive is used to pass a class to element in regard of element inside it
 * first it take img tag
 * it's for example html content insert into an element
 */
export class AddClassIfContainsPictureDirective implements OnChanges, AfterViewInit {

    @Input() elementToWatch = '';

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngAfterViewInit(): void {
        this.injectClassIfContainsImage();
    }

    ngOnChanges(changes: SimpleChanges): void{
        this.injectClassIfContainsImage();
    }

    /**
     * inject class in regard of length text l1 one row l2 two row l3 three row etc...
     * css will be adjust to increase height, change img etc.. in regard of this class
     * @private
     */
    private injectClassIfContainsImage(): void {
        this.renderer.removeClass(this.el.nativeElement, 'is-picture');
        try {
            const img = this.elementToWatch.includes('<img');
            if ( img || this.el.nativeElement.innerHTML.includes('<img') ) {
                this.renderer.addClass(this.el.nativeElement, 'is-picture');
            }

        }catch (e){
            console.log('NO IMAGE');
        }
    }
}
