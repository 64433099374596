import {Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, ViewEncapsulation} from '@angular/core';
import {GamificationService} from '../gamification.service';
import {DataEntity} from 'octopus-connect';
import {takeUntil} from 'rxjs/operators';
import {Creature} from '../definitions';
import {AccessoriesPopupComponent} from '@modules/gamification/core/accessories-popup/accessories-popup.component';

@Component({
    selector: 'app-animal-controls',
    templateUrl: './animal-controls.component.html',
    styleUrls: ['./animal-controls.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AnimalControlsComponent implements OnInit {

    @Output()
    next = new EventEmitter<void>();

    @Output()
    previous = new EventEmitter<void>();

    @Output()
    saveUserImage = new EventEmitter<boolean>();

    @Output()
    update = new EventEmitter<Array<DataEntity> | null>();

    @ViewChild('container', {static: true}) container: ElementRef;
    @ViewChild('popup', {static: true}) popup: AccessoriesPopupComponent;

    private _width: number;
    accessories: DataEntity[];

    isLoading = false;

    @Input()
    set width(width: number) {
        if (width) {
            this.container.nativeElement.style.width = width.toString() + 'px';
            this._width = width;
        }
    }

    get width(): number {
        return this._width;
    }

    @Input()
    navigationAllowed = false;

    private _controlsAllowed = true;
    @Input()
    set controlsAllowed(controlsAllowed: boolean) {
        this._controlsAllowed = controlsAllowed;
    }

    get controlsAllowed(): boolean {
        return this._controlsAllowed && this.popup.showPopup === false && this.isLoading === false;
    }

    @Output()
    elements = new EventEmitter<Array<DataEntity>>();


    private _creature: Creature;
    /**
     * Getter creature
     * @return {Creature}
     */
    public get creature(): Creature {
        return this._creature;
    }

    /**
     * Setter creature
     * @param {Creature} value
     */
    @Input()
    public set creature(value: Creature) {
        this._creature = value;
        this.name = this.creature.creature.attributes.label;
        this.accessories = this._creature.accessories;
    }

    get progress(): number {
        if (!this.accessories) {
            return 0;
        }
        return this.accessories.filter(a => a.attributes.unLocked === true).length;
    }

    get totalProgress(): number {
        if (!this.accessories) {
            return 1;
        }
        return this.accessories.length;
    }

    get progressBarWidth(): number {
        let res = (this.progress / this.totalProgress) * 100;
        if (res === 0) {
            res = 5;
        }
        return res;
    }

    get progressBarBackgroundWidth(): number {
        return (100 / this.progressBarWidth) * 100;
    }

    /**
     * Getter $name
     * @return {string}
     */
    public get $name(): string {
        return this.name;
    }

    /**
     * Setter $name
     * @param {string} value
     */
    public set $name(value: string) {
        this.name = value;
    }

    private name: string;
    isNameEditing = false;

    constructor(private gamificationService: GamificationService) {
    }

    ngOnInit(): void {
    }

    onPrevious(): void {
        if (this.controlsAllowed !== true) {
            return;
        }
        this.previous.emit();
    }

    onNext(): void {
        if (this.controlsAllowed !== true) {
            return;
        }
        this.next.emit();
    }

    onSetAvatar(): void {
        if (this.controlsAllowed !== true || this.creature.creature.attributes.selected === true) {
            return;
        }
        if (this.creature.creature.attributes.userImageFid) {
            this.gamificationService.setAvatar(this.creature);
        } else {
            this.saveUserImage.emit(true);
        }
    }

    onSaveUserImage(setAvatar: boolean): void {
        this.saveUserImage.emit(setAvatar);
    }

    editName(): void {
        if (this.controlsAllowed !== true) {
            return;
        }
        this.isNameEditing = true;
    }

    submitName(): void {
        if (this.controlsAllowed !== true) {
            return;
        }
        this.isLoading = true;
        this.isNameEditing = false;
        this.gamificationService.setName(this.creature.creature, this.name).then((newCreature) => {
            this.name = newCreature.attributes.label;
            this.isLoading = false;
        }).catch(err => {
            console.log('Error updating name', err);
            this.isLoading = false;
        });
    }

    triggerUpdateEvent(elements: Array<DataEntity> | null): void {
        this.update.emit(elements);
    }
}
