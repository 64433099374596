import {Component, Input, OnInit} from '@angular/core';
import {MainMenuElementLinkInterface} from '../../models/main-menu-element-link.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {Router} from '@angular/router';
import {CollectionOptionsInterface, DataCollection} from 'octopus-connect';
import {map, mergeMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@modules/authentication';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';

@Component({
    selector: 'app-main-menu-element-link',
    templateUrl: './main-menu-element-link.component.html'
})
export class MainMenuElementLinkComponent implements OnInit {
    @Input() public element: MainMenuElementLinkInterface;
    @Input() public externalUrl: boolean;
    @Input() public openInNewTab: boolean;
    // cypress e2e test does not support . separator so for automatic flag we replace all .  by _
    public regex = new RegExp('\\.', 'g');
    public assignmentCount: number;
    constructor(
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private conf: LessonsConfigurationService
    ) {
    }

    ngOnInit(): void {
        // TODO : take good value and refacto to avoid multiple call for same value (cf period-list.component)
        if(this.element.label === 'generic.menu-assignments'){
            const optionsInterface: CollectionOptionsInterface = {
                filter: {
                    'assignated_user': this.authenticationService.userData.id,
                    'excludeAssignator': true,
                    // 2683=pending 2681=assigned 2682=closed 4270=valid;
                    'assignments_state': this.conf.getIdFilterToUseInMyLessonButton()
                }
            };

            this.communicationCenter.getRoom('assignment')
                .getSubject('loadPaginatedAssignmentsCallback')
                .pipe(
                    mergeMap((loadPaginatedAssignments$: (filters?: CollectionOptionsInterface) => Observable<DataCollection>) =>
                        loadPaginatedAssignments$(optionsInterface.filter)
                    ),
                    take(1),
                    map(collection => collection.entities)
                ).subscribe(ass => {
                // set the number of assigment
                this.assignmentCount = ass.length;
            });

            this.communicationCenter
                .getRoom('assignment')
                .getSubject('assignmentsChangedNumber')
                .subscribe((assignmentsCount: number) => {
                    this.assignmentCount = assignmentsCount;
                });
        }
    }

    public onLinkClick(_$event: MouseEvent): void {
        this.communicationCenter.getRoom('mainMenu').next('onMenuLinkClick', null);
    }

    public onLinkClickExternal(_$event: MouseEvent, href, target): void {
        this.communicationCenter.getRoom('mainMenu').next('onMenuLinkClick', null);
        window.open(href, target ? '_blank' : '_self');
    }

    getLink(path: string | (() => string)) {
        if (typeof path === 'function')
            return path()

        return path
    }
}
