export const groupsManagement = {
    accessGroupsManager: ['manager', 'administrator'],
    accessGroupsTrainer: ['trainer', 'administrator'],
    authorizedRoles: ['learner', 'trainer', 'manager', 'administrator'],
    canBeDelete: {
        workgroup: true,
        institution: false,
        learner: true,
        learners: false,
    },
    canBeJoined: {
        group: true
    },
    collapsibleFields: ['school', 'archived', 'button-video-help', 'nickname', 'workgroupAssociate', 'groupAssociate'],
    displayedFilters: {
        default: ['school', 'workgroup', 'groups', 'archived', 'button-video-help', 'nickname', 'workgroupAssociate', 'groupAssociate']
    },
    displayHeader: false,
    displayNews: {
        default : [],
        trainer: ['createGroup', 'createLearner']
    },
    group: {
        columns: {
            trainer: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            administrator: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            manager: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            default: ['color', 'groupname', 'level', 'code']
        }
    },
    groupType: 2,
    idDefiTaxonomy: '5427', // id of the defi in list activity type
    idActivityToHideInProgressTab: [], // ex :['5445'] use because somme activity in mathia must be hide in progress and not in activity tab
    institutionGroupType: 52,
    isPasswordRequired: false, // when teacher create student account is password require
    learner: {
        columns: {
            default: ['checkbox', 'avatar', 'nickname', 'pseudo', 'groups', 'workgroups', 'parentalConsent', 'results', 'learnerCode', 'buttons' ]
        },
        createFields: { default: ['avatar', 'username', 'password', 'groups', 'workgroups', 'parentalConsent']},
        editFields: { default: ['id', 'avatar', 'username', 'password', 'groups', 'workgroups', 'parentalConsent']}, // do not remove 'id' because it's needed to update data
        joinFields: { default: ['avatar', 'username', 'password', 'groups', 'workgroups', 'parentalConsent']},
    },
    levelsWidget: 'select',
    primaryColors: ['#C5629D', '#FF9EA1', '#EEDA78', '#6FF2C5', '#6DE6E2', '#305792'],
    showGlobalAddButton: false, // show main button to add class group or user that open modal dialog if false it's inline add
    workgroupType: 3,
    importInClasses: false,
    setTeacherRegionWhenAddNewLearner: true, // set by default the teacher value when adding a new learner

};
