import {AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {brand, brandLogoSvg, defaultLoginRoute, captcha} from '../../../../settings';
import {AccountManagementProviderService} from '../account-management-provider.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {TranslateService} from '@ngx-translate/core';
import {localizations, WidgetInstance, WidgetInstanceOptions} from 'friendly-challenge';
import {Router} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';

/**
 * this component is used to create account without setting a password
 * user create account step by step
 * in reality login is created but with a rules and user never see it
 * login = codeid + @opasswordregistration.tralalere.com
 */
@Component({
    selector: 'app-register-easly',
    templateUrl: './register-easly.component.html'
})
export class RegisterEaslyComponent implements OnInit, AfterViewInit, OnDestroy {
    registerForm: UntypedFormGroup;
    public requestValidate: boolean;
    public captcha = captcha;
    @ViewChild('widgetFC') widgetFC: ElementRef;
    private friendlyCaptchaSolution: string;
    private widget: any;
    public step = 0;
    public currentUser: DataEntity;
    public captchaOk = false;
    private youAre = ''; // teen or teacher
    public errorCodeClass = ''; // if class code set is not good
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public showLoader = false;
    // different title that can be added
    public stepTitle = ['account-management.title-1', 'account-management.title-2', 'account-management.title-3', 'account-management.title-4'];
    // different info that can be added in regard of step position
    public stepInfo = [{part1: 'account-management.info-1', part2: '', part3: ''},
        {part1: 'account-management.info-2', part2: '', part3: ''},
        {part1: 'account-management.info-3-part-1', part2: 'account-management.info-3-part-2', part3: 'account-management.info-3-part-3'},
        {part1: 'account-management.info-4-part-1', part2: 'account-management.info-4-part-2', part3: ''}];
    public educationalLevels: DataEntity[] = [];
    public isSelectLevelEnabled = false;
    private levelSelectedId = null;
    private singleExecutionSubscription: Subscription;
    recaptchaV3Service: any;

    constructor(private formBuilder: UntypedFormBuilder,
                public accountProvider: AccountManagementProviderService,
                private octopusConnect: OctopusConnectService,
                public profileService: ProfileService,
                private translate: TranslateService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private communicationCenter: CommunicationCenterService,
                private injector: Injector,
    ) {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-login-or-register-active');
    }

    ngOnInit(): void {
        if (this.captcha === 'recaptcha') {
            this.recaptchaV3Service = <ReCaptchaV3Service>this.injector.get(ReCaptchaV3Service);
        }
        this.accountProvider.getEducationalLevels().pipe(
            tap((levelEntities: DataEntity[]) => this.educationalLevels = levelEntities)
        ).subscribe();
        this.requestValidate = false;
        this.generateRegisterForm();
        this.managePseudoError();
    }

    /**
     * manage error :
     * set error when pseudo has a bad format
     * and reset error when we type a new code class
     * @private
     */
    private managePseudoError(): void {
        this.registerForm.controls['pseudo'].valueChanges.subscribe(pseudo => {
            if (!this.validateField(pseudo)) {
                this.registerForm.controls['pseudo'].setErrors({badFormat: true});
            } else {
                this.registerForm.controls['pseudo'].setErrors(null);
            }
        });

        this.registerForm.controls['class_code'].valueChanges.subscribe(classeCode => {
            if (classeCode !== '') {
                this.errorCodeClass = '';
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.captcha === 'friendlyCaptcha') {
            const doneCallback = (solution: string): any => {
                this.friendlyCaptchaSolution = solution;
            };

            const errorCallback = (err: any): any => {
                console.log('There was an error when trying to solve the Captcha.');
                console.log(err);
            };
            const lang = this.translate.currentLang as keyof typeof localizations;
            const element = this.widgetFC.nativeElement;
            const options: Partial<WidgetInstanceOptions> = {
                startMode: 'none',
                doneCallback: doneCallback,
                errorCallback: errorCallback,
                language: lang
            };

            this.widget = new WidgetInstance(element, options);

            // this makes the widget fetch a puzzle and start solving it.
            this.widget.start();
        }
    }

    /**
     * only letter without accent , number and unserscore withour space
     * @param field : form field value to test
     */
    validateField(field): boolean {
        let re;
        re = /^\w+(\s\w+)*$/; // only letter without accent, number and underscore no space possible except one beetween word
        return re.test(String(field).toLowerCase());
    }

    /**
     * add control to form
     * @private
     */
    private generateRegisterForm(): void {
        this.registerForm = this.formBuilder.group({
            pseudo: ['', Validators.required],
            class_code: ['', null]
        });
    }

    /**
     * store role of user to create
     * @param role
     */
    public setRole(role: string): void {
        this.youAre = role;
        this.step++;
    }

    // todo when create part of teacher make an extend of standard register to manage diff without code all
    registerTeacher(): void {
        this.router.navigate(['/register'], {queryParams: {role: 'teacher'}});
    }

    /**
     * form pass to next step and show next field to fullfill
     */
    public nextStep(): void {
        if (this.step === 2 && (this.codeClassEntered && this.codeClassEntered !== '' || this.levelSelectedId)) {
            this.errorCodeClass = '';
            this.showLoader = true;
            this.captchaOk = false;

            switch (this.captcha) {
                case 'recaptcha':
                    if (this.singleExecutionSubscription) {
                        this.singleExecutionSubscription.unsubscribe();
                    }
                    this.singleExecutionSubscription = this.recaptchaV3Service.execute('registerForm')
                        .subscribe((token) => {
                                this.accountProvider.verifyToken(token).subscribe(verifyResult => {
                                    // if score >= 0.7 (0 = bot -> 1 = human)
                                    // (google api is returning success: boolean, challenge_ts: string, hostname: string, score: number, action: string)
                                    if (verifyResult['data'][0]['score'] >= 0.7) {
                                        this.captchaOk = true;
                                        this.preCreateUser();
                                    } else {
                                        this.captchaOk = false;
                                    }
                                });
                            }
                        );
                    break;

                case 'friendlyCaptcha':
                    if (this.friendlyCaptchaSolution) {
                        this.checkSolution();
                        this.widget.reset(); // the widget need to be reset because it's working only one time
                    } else {
                        this.widget.start();
                        this.checkSolution();
                    }
                    break;
                    this.widget.reset(); // the widget need to be reset because it's working only one time
                    this.widget.start();
                    break;

                case 'hcaptcha':
                    if (this.registerForm.get('captcha').status === 'VALID' && this.registerForm.get('captcha').value !== '') {
                        this.captchaOk = true;
                    } else {
                        this.captchaOk = false;
                    }
                    break;

                default:
                    console.log('no captcha in setting');
            }
        }

        // ask for creating code class and create account
        if (this.step === 2 && (!this.codeClassEntered || this.codeClassEntered === '')) { // NJ435 coce class for test
            this.isSelectLevelEnabled = true;
        }

        // goto home page logged
        if (this.step === 3) {
            this.autoConnectNewUser();
        }
        // all step gone to next automaticly except during creating user because we check if class code exist
        if (this.step !== 2) {
            this.step++;
        }
    }

    checkSolution(): void {
        this.accountProvider.verifyToken(this.friendlyCaptchaSolution).subscribe(verifyResult => {
                if (verifyResult['data'][0]['success'] === true) {
                    this.captchaOk = true;
                    this.preCreateUser();
                } else {
                    this.captchaOk = false;
                }
            },
            error => {
                if (error.status !== 200) {  // Verification Best practices https://docs.friendlycaptcha.com/#/installation
                    this.captchaOk = true;
                    this.preCreateUser();
                } else {
                    this.captchaOk = false;
                }
            });
    }

    preCreateUser(): void{
        this.createUser().subscribe((userData) => {
            this.isSelectLevelEnabled = false;
            this.currentUser = userData;
            this.step++;
            this.showLoader = false;
        }, error => {
            this.errorCodeClass = error.data.response.title;
            this.registerForm.controls['class_code'].setValue('');
            this.showLoader = false;
        });
    };

    /**
     * create new user with no password and
     * @private
     */
    private createUser(): Observable<DataEntity> {
        let newUser;
        newUser = {
            noPasswordRegistration: true,
            label: this.registerForm.value.pseudo,
            groupCode: this.registerForm.value.class_code,
            you_are: this.youAre
        };
        if (this.levelSelectedId) {
            newUser.level = this.levelSelectedId;
        }
        return this.octopusConnect.createEntity('user-registration', newUser);
    }

    /**
     * connect user previously created and launch app
     * @private
     */
    private autoConnectNewUser(): void {
        this.showLoader = true;
        // account is generated with the rule codeid and @opasswordregistration.tralalere.com
        const account = this.currentUser.get('codeid') + '@nopasswordregistration.tralalere.com';
        this.authenticationService.authenticateIn('http', account, this.currentUser.get('codeid'))
            .subscribe(user => {
                this.router.navigate([defaultLoginRoute]);
                this.showLoader = false;
            }, err => {
                console.log(err);
                this.showLoader = false;
            });
    }

    public currentStepHasError(): boolean {
        // pseudo
        if (this.step === 1) {
            // todo add message wait for verification you are human
            return this.registerForm.controls['pseudo'].status === 'INVALID';
        }
        return false;
    }

    /**
     * code is shown in format XXXX-XXXX-XXXX
     */
    formatCodeClass(clipboard?: boolean): string {
        if (this.currentUser && this.currentUser.get('codeid')) {
            let separator = '-';
            if (clipboard !== true) {
                // add span to custom display
                separator = '<span>-</span>';
            }
            return this.currentUser.get('codeid').substring(0, 4)
                + separator + this.currentUser.get('codeid').substring(4, 8)
                + separator + this.currentUser.get('codeid').substring(8, this.currentUser.get('codeid').length);
        } else {
            return '';
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-login-or-register-active');
    }

    public selectLevel(classSelected: DataEntity): void {
        this.levelSelectedId = classSelected.id;
        this.nextStep();
    }

    public get codeClassEntered(): string {
        return this.registerForm && this.registerForm.controls['class_code'].value;
    }
}



