import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-no-assignment-for-long-time-ago-news',
  templateUrl: './no-assignment-for-long-time-ago-news.component.html',
  styleUrls: ['./no-assignment-for-long-time-ago-news.component.scss']
})
export class NoAssignmentForLongTimeAgoNewsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

    public goToLessonsPage(): void {
        this.router.navigate(['/lessons/list/models']);
    }

}
