import {take, map, switchMap, tap, filter, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings, defaultApiURL} from '../../../settings';
import {DataEntity, DataCollection, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {Observable, Subject, Subscription, BehaviorSubject, zip, of, throwError, combineLatest} from 'rxjs';
import {CreatureCollection, Creature} from './definitions';
import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management';
import {TranslateService} from '@ngx-translate/core';
import {Data} from '@angular/router';
import {EventService} from '../../../shared/event.service';

const settingsStructure: ModelSchema = new ModelSchema({
    showRewards: Structures.boolean(false),
    badgeTypes: Structures.array([]),
    badgeImageIcon: Structures.array([]),
    badgeImageIconBigger: Structures.array([]),
    badgeTypeAnimations: Structures.array([]),
    defaultAnimationName: Structures.string(),
    skeletonAtlas: Structures.string(),
    skeletonJson: Structures.string(),
    defaultSkinName: Structures.string()

});

@Injectable({
    providedIn: 'root'
})
export class GamificationService {

    private badgesSubscription: Subscription;
    public badgesTypes: Array<DataEntity>;
    public settings: { [key: string]: any };
    public userPoints: number;
    public userLevel: number;
    public progress: number;
    public pointsToNextLevel: number;
    public isShowPopup: boolean;
    public avatarWasLoadOnce = false;
    public activeTab: 'accessories' | 'universes' = 'accessories';
    public originalAccessoriesBeforeBuyingAnotherOne = new Array<DataEntity>(); // list of accessories before buying one permit to return back if new one is not save
    /**
     * Cache of accessories used to remember which accessory are currently bought
     */
    public accessoriesCache: { [p: string]: DataEntity } = {};
    private _buyPopupInfo: {
        hidden: boolean,
        badge: DataEntity,
        buyCallback: (data: DataEntity) => void
    } = {
        hidden: true,
        badge: null,
        buyCallback: null
    };

    // iBoost specific badges
    public badges: CreatureCollection;
    // Animable badges
    public skins: DataEntity[] = [];
    public skinsObservable: Subject<DataEntity[]> = new BehaviorSubject([]);
    public animationObservable: Subject<string> = new BehaviorSubject('dance1');

    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';
    private _userProfile: DataEntity;

    private userAdvancementSubject: Subject<any>;
    public userData: DataEntity;


    constructor(
        private communicationCenter: CommunicationCenterService,
        private connector: OctopusConnectService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private eventService: EventService
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.gamification);
        this.animationObservable = new BehaviorSubject(this.settings.defaultAnimationName);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this._userProfile = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.userAdvancementSubject = this.communicationCenter
            .getRoom('gamification')
            .getSubject('levelData');

        this.communicationCenter
            .getRoom('gamification')
            .getSubject('loadAvatar')
            .subscribe(launch => {
                if (launch) {
                    this.loadAvatar();
                }
            });

        this.communicationCenter
            .getRoom('gamification')
            .getSubject('refreshAdvancementData')
            .subscribe(launch => {
                if (launch) {
                    this.refreshAdvancementData();
                }
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
            });
    }

    private postLogout(): void {
    }

    private postAuthentication(): void {
        if (this.settings.showRewards) {
            this.refreshAdvancementData();
            this.connector.listen('reward')
                .subscribe((reward: DataEntity) => {
                    this.translate.get(reward.get('body')).subscribe((translation) => {
                        const body = translation.replace('{{params}}', reward.get('params'));
                        this.openRewardDialog({
                            titleDialog: '',
                            bodyDialog: body,
                            labelTrueDialog: 'OK'
                        });
                    });
                    this.loadBadges();
                });
        }
    }

    public loadBadges(force?): void {
        this.getUserPoints();
        this.badges = new CreatureCollection(this);
        this.translate.onLangChange.subscribe(() => {
            this.badges.loadCreatures(true);
            this.badges.loadUniverses(true);
        });
        this.badges.loadCreatures(force);
        this.badges.loadUniverses(force);
    }

    loadAvatar(): void {
        this.getBadgeTypes().subscribe(obs => {
            const dataObservable = [];
            for (const badgeType of this.settings.badgeTypes) {
                if (this.getBadges(badgeType)) {
                    dataObservable.push(this.getBadges(badgeType));
                }
            }
            zip<DataCollection[]>(...dataObservable).subscribe((datas) => {
                this.skins = [];
                for (const collection of datas) {
                    this.skins.push(...collection.entities);
                    this.skinsObservable.next(this.skins);
                    this.avatarWasLoadOnce = true;
                }
            });
        });
    }

    private openRewardDialog(config: object): void {
        this.dialog.open(FuseConfirmDialogComponent, {data: config});
    }

    openBuyPopup(badge: DataEntity, buyCallback: (data: DataEntity) => void): void {
        if (!badge) {
            return;
        }
        this._buyPopupInfo = {
            badge: badge,
            hidden: false,
            buyCallback
        };
    }

    closeBuyPopup(): void {
        this._buyPopupInfo = {
            hidden: true,
            badge: null,
            buyCallback: null
        };
    }

    buy(badgeSelected: DataEntity): Observable<DataEntity> {
        const badge: DataEntity = this.skins.find((item: DataEntity) => item.id === badgeSelected.id);
        if (this.userPoints < badge.attributes['price']) {
            return of(badge).pipe(tap(() => {
                throwError('Too Expensive'); // Ne devrai jamais arriver car on test avant d'appeller buy le prix du badge
            }));
        }

        this.userPoints -= badge.attributes.price;
        badge.set('unLocked', true);
        return this.equipSkin(badge).pipe(
            tap(() => this.refreshAdvancementData())
        );
    }

    buyBadge(): Promise<DataEntity> {
        return new Promise(async (resolve, reject) => {
            if (!this._buyPopupInfo.badge) {
                reject('No badge provided');
            }
            const checkedBadge = await this.getBadge(this._buyPopupInfo.badge.id);
            await this.getUserPoints();
            if (this.userPoints < checkedBadge.attributes.price) {
                reject('Too expensive');
            } else {
                checkedBadge.attributes.unLocked = true;
                this._buyPopupInfo.badge.attributes.unLocked = true;
                this.userPoints -= checkedBadge.attributes.price;
                checkedBadge.save(true).subscribe(res => {
                    this.badges.updateBadge(res, true, true);
                    this.refreshAdvancementData(); // Need to refresh and propagate all advancement (coin & lvl to subscribers)
                    this._buyPopupInfo.buyCallback(res);
                    resolve(res);

                }, err => {
                    reject(err);
                });
            }
        });
    }

    async setSelectedCreatureOrAccessory(collection: Array<DataEntity>, badge: DataEntity, valueToSet: boolean): Promise<DataEntity> {
        if (badge.attributes.unLocked === false) {
            throw new Error('Badge locked');
        }

        if (valueToSet === true) {
            const previous = collection.filter(
                b => b.attributes.selected === true &&
                    (!b.attributes.stuffType && !badge.attributes.stuffType || b.attributes.stuffType.id === badge.attributes.stuffType.id)
            ).map(b => {
                b.attributes.selected = false;
                return new Promise<DataEntity>((resolve, reject) => {
                    b.save().subscribe(res => {
                        this.badges.updateBadge(res);
                        resolve(res);
                    }, err => {
                        reject(err);
                    });
                });
            });
            await Promise.all(previous);
        }
        badge.attributes.selected = valueToSet;
        return new Promise<DataEntity>((resolve, reject) => {
            badge.save().subscribe(res => {
                this.badges.updateBadge(res);
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    setName(badge: DataEntity, name: string): Promise<DataEntity> {
        return new Promise((resolve, reject) => {
            if (!name) {
                reject('Empty name');
            }
            badge.attributes.label = name;
            badge.save().subscribe(res => {
                this.badges.updateBadge(res);
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    get buyPopupInfo(): {
        hidden: boolean;
        badge: DataEntity;
    } {
        return this._buyPopupInfo;
    }

    getBadges(name?: string, parent?: string): Observable<DataCollection> {
        if (this.badgesSubscription) {
            this.badgesSubscription.unsubscribe();
        }
        if (name) {
            if (this.badgesTypes) {
                const badgeTypeId = this.badgesTypes.find(bt => bt.attributes.name === name) ? this.badgesTypes.find(bt => bt.attributes.name === name).id : undefined;
                if (!badgeTypeId) {
                    return;
                }
                const filter = {
                    type: badgeTypeId,
                    parent: parent
                };
                if (!parent) {
                    delete filter.parent;
                }
                return this.connector.loadCollection('badges', filter).pipe(take(1));
            } else {

                return this.getBadgeTypeId(name).pipe(
                    switchMap(badgeTypeId => {
                        if (!badgeTypeId) {
                            return;
                        }
                        const filter = {
                            type: badgeTypeId,
                            parent: parent
                        };
                        if (!parent) {
                            delete filter.parent;
                        }
                        return this.connector.loadCollection('badges', filter).pipe(take(1));
                    })
                );
            }
        } else {
            return this.connector.loadCollection('badges').pipe(take(1));
        }
    }

    public saveBadge(badge: DataEntity): Observable<DataEntity> {
        return badge.save(true);
    }

    getBadge(id: string | number): Promise<DataEntity> {
        return new Promise<DataEntity>((resolve, reject) => {
            this.connector.loadEntity('badges', this._buyPopupInfo.badge.id).subscribe(badge => {
                resolve(badge);
            }, err => {
                reject(err);
            });
        });
    }

    getBadgeTypeId(name: string): Observable<any> {
        return this.connector.loadCollection('badges-type').pipe(map(res => {
            this.badgesTypes = res.entities;
            return res.entities.find(e => e.attributes.name === name) ? res.entities.find(e => e.attributes.name === name).id : of(null);
        }), take(1));
    }

    getBadgeTypes(): Observable<any> {
        return this.connector.loadCollection('badges-type').pipe(map(res => {
            this.badgesTypes = res.entities;
        }), take(1));
    }

    getUserPoints(): Promise<number> {
        return new Promise<number>((resolve, reject) => {

            // TODO utiliser service mutualisé
            this.connector.loadCollection('user-points').subscribe(res => {
                this.userPoints = res.entities[0].attributes.points;
                // if available sets the level
                if (res.entities[0].attributes.level) {
                    this.userLevel = res.entities[0].attributes.level;
                }
                resolve(res.entities[0].attributes.points);
            }, err => {
                reject(err);
            });
        });
    }

    /*** Reload points data, will trigger all subscribers of loadCollection('user-points') */
    refreshAdvancementData(): void {
        // TODO utiliser service mutualisé
        this.connector.loadCollection('user-points')
            .pipe(
                take(1),
                filter((res) => !!res),
                map(res => {
                    this.userPoints = res.entities[0].attributes.points !== undefined ? res.entities[0].attributes.points : 0;
                    this.userLevel = res.entities[0].attributes.level !== undefined ? res.entities[0].attributes.level : 1;
                    this.progress = res.entities[0].attributes.progress !== undefined ? res.entities[0].attributes.progress : 0;
                    this.pointsToNextLevel = res.entities[0].attributes?.pointsToNextLevel !== undefined ? res.entities[0].attributes.pointsToNextLevel : 999999;
                    return {'points': this.userPoints, 'level': this.userLevel, 'progress': this.progress, pointsToNextLevel: this.pointsToNextLevel};
                })
            )
            .subscribe(() => {
                this.userAdvancementSubject.next({'points': this.userPoints, 'level': this.userLevel, 'progress': this.progress, pointsToNextLevel: this.pointsToNextLevel});
            });
    }

    uploadImage(creature: Creature, imageData: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {

            if (!this._userProfile) {
                reject('No profile data');
            }

            const f = await fetch(imageData);
            const blob = await f.blob();

            const formData = new FormData();
            formData.append('file', blob, 'avatar.png');

            this.http
                .post<any>(this._urlFileUpload, formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                .subscribe((fileUploadRes) => {
                    if (fileUploadRes && fileUploadRes.data && fileUploadRes.data[0] && fileUploadRes.data[0][0] && fileUploadRes.data[0][0].id) {
                        const newId = fileUploadRes.data[0][0].id;
                        creature.creature.attributes.fid = newId;
                        creature.creature.save().subscribe(async creatureUpdateRes => {
                            creature.creature.attributes.userImage = creatureUpdateRes.attributes.userImage;
                            creature.creature.attributes.userImageFid = creatureUpdateRes.attributes.userImageFid;
                            if (creature.creature.attributes.selected === true) {
                                const newCreatureAdterAvatar = await this.setAvatar(creature);
                                resolve(newCreatureAdterAvatar);
                            } else {
                                resolve(creatureUpdateRes);
                            }
                        }, err => {
                            reject(err);
                        });
                    } else {
                        reject('Empty uploaded file data');
                    }
                }, err => {
                    reject(err);
                });
        });
    }

    setAvatar(creature: Creature): Promise<Creature> {
        return new Promise<Creature>(async (resolve, reject) => {

            if (!this._userProfile) {
                reject('No profile data');
            }

            this._userProfile.set('_picture', creature.creature.get('userImageFid'));
            this._userProfile.save().subscribe(async userProfileUpdateRes => {
                creature.creature = await this.setSelectedCreatureOrAccessory(this.badges.creatures.map(cr => cr.creature), creature.creature, true);
                resolve(creature);

                this.communicationCenter
                    .getRoom('account-management')
                    .next('refreshUser', true);
            }, err => {
                reject(err);
            });
        });
    }

    startPersonalisation(): void {
        this.connector.loadEntity('badges', 'start').pipe(take(1));
    }

    equipSkin(badgeToSelect: DataEntity): Observable<DataEntity> {
        const badge: DataEntity = this.skins.find((item: DataEntity) => item.id === badgeToSelect.id);
        // 1 seul badge par type ne peut être sélectionné
        const badgesOfSameTypeObs: Observable<DataEntity>[] = this.skins
            .filter((skinDataEntity) => skinDataEntity.get('selected') && skinDataEntity.get('type')['id'] === badge.get('type')['id'])
            .map((badgeToEdit: DataEntity) => {
                badgeToEdit.set('selected', false);
                return badgeToEdit.save(true);
            });

        if (badgesOfSameTypeObs.length === 0) {
            // if no element selected before filter suppress all result and never go in map => combinelatest failed in this case
            // this case will append only if we suppress auto select in place after buying an item
            return this.saveSelectedItem(badge);
        } else {
            return combineLatest(badgesOfSameTypeObs).pipe(
                mergeMap(() => {
                    return this.saveSelectedItem(badge);
                })
            );
        }
    }

    /**
     * save the current badge selected with the state selected
     * @param badge
     * @private
     */
    private saveSelectedItem(badge: DataEntity): Observable<DataEntity> {
        badge.set('selected', true);
        return badge.save(true)
            .pipe(
                tap(() => this.skinsObservable.next(this.skins))
            );
    }

    unEquipSkin(badgeToDeSelect: DataEntity): Observable<DataEntity> {
        const badge: DataEntity = this.skins.find((item: DataEntity) => item.id === badgeToDeSelect.id);
        badge.set('selected', false);
        return badge.save(true)
            .pipe(
                tap(() => this.skinsObservable.next(this.skins))
            );
    }

    isAnimation(badge: DataEntity): boolean {
        const badgesTypeAnimation = this.settings.badgeTypeAnimations;
        return badgesTypeAnimation.find(animationBadgeType =>
            badge.attributes['type']['name'] === animationBadgeType
        );
    }

    // Désactive l'animation courrante et lance la nouvelle
    switchAnimationTo(data: { isSelected: boolean, badge: DataEntity }): Observable<DataEntity> {
        // besoin de mettre à false l'animation courrante (ou on set à la défault)
        // Déséquiper une animation lance l'animation par défaut
        const entitySelected = this.skins.find((entity: DataEntity) => entity.id === data.badge.id);
        if (!data.isSelected) {
            entitySelected.set('selected', false);
            return entitySelected.save(true).pipe(
                tap(() => this.startOrStopAnimation())
            );
        } else {
            const animationToDeselectObs = this.skins.filter((badge: DataEntity) => this.isAnimation(badge))
                .map((badge: DataEntity) => {
                    badge.set('selected', false);
                    return badge.save(true);
                });
            return combineLatest(animationToDeselectObs).pipe(
                mergeMap(() => {
                    entitySelected.set('selected', true);
                    return entitySelected.save()
                        .pipe(
                            tap(() => this.startOrStopAnimation(entitySelected.get('label')))
                        );
                })
            );
        }
    }

    public doActionDependingOnType(data: { isSelected: boolean, badge: DataEntity }): Observable<DataEntity> {
        if (data.badge.get('unLocked')) {
            if (this.isAnimation(data.badge)) {
                return this.switchAnimationTo(data);
            } else {
                if (data.isSelected) {
                    return this.equipSkin(data.badge);
                } else {
                    return this.unEquipSkin(data.badge);
                }
            }
        } else {
            return this.buy(data.badge);
        }
    }

    public startOrStopAnimation(animationFromBadge?: string): void {
        this.animationObservable.next(animationFromBadge ? animationFromBadge : this.settings.defaultAnimationName);
    }
}
