import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

    constructor(
        public authenticationService: AuthenticationService,
    ) {
        this.authenticationService.logoutFrom('http');
    }

    ngOnInit(): void {
    }

}
