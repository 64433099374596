import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { DataEntity, OctopusConnectService } from "octopus-connect";
import { Observable, Subject } from "rxjs";

import { AuthenticationService } from "../authentication.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-user-session",
  template: "",
})
export class SessionComponent implements OnInit, OnDestroy {
  private redirectPath: string;
  private unsubscribeInTakeUntil = new Subject<void>();

  constructor(
    private connector: OctopusConnectService,
    private route: ActivatedRoute,
    public router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    const { redirectPath, token } = this.route.snapshot.queryParams as {
      token: string;
      redirectPath: string;
    };
    if (token) {
      this.setToken(token);
    }
    if (redirectPath) {
      this.redirectPath = redirectPath;
    }
    if (!token || !redirectPath) {
      this.router.navigate(["login"]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeInTakeUntil.next();
    this.unsubscribeInTakeUntil.complete();
  }

  setToken(token): void {
    let myDate = new Date();
    let timestampDate;
    myDate.setHours(myDate.getHours() + 24);
    timestampDate = Math.floor(new Date(myDate).getTime());

    localStorage.setItem("http_accessToken", JSON.stringify(token));
    localStorage.setItem("http_expires_in", JSON.stringify(timestampDate));
    localStorage.setItem("http_currentUser", JSON.stringify({}));

    const obs: Observable<DataEntity> = this.connector.authenticated("http");

    obs.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(
      (userData: DataEntity) => {
        this.authenticationService.onAuthenticated(userData);
        this.router.navigate([this.redirectPath], {
          queryParamsHandling: "preserve",
        });
      },
      () => {
        console.log("error");
      }
    );
  }
}
