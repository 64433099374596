import { ModuleWithProviders, NgModule } from "@angular/core";

import { AuthenticationService } from "./authentication.service";
import { CanAccessToAuthenticationFeaturesGuard } from "./guards/can-access-to-authentication-features.guard";
import { ConnectViaGarComponent } from "./connect-via-gar/connect-via-gar.component";
import { FuseForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { FuseLoginComponent } from "./login/login.component";
import { FusePasswordResetEmailComponent } from "@modules/authentication/core/password-reset-email/password-reset-email.component";
import { FuseResetPasswordComponent } from "./reset-password/reset-password.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { IsUserAnonymous } from "../../../guards/is-user-anonymous.class";
import { IsUserLogged } from "../../../guards/is-user-logged.class";
import { LogoutComponent } from "./logout/logout.component";
import { ManageConnectPageComponent } from "@modules/authentication/core/manage-connect-page/manage-connect-page.component";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { QuickConnectPageComponent } from "@modules/authentication/core/quick-connect-page/quick-connect-page.component";
import { RegistrationCodeDirective } from "@modules/authentication/core/directives/registration-code";
import { RouterModule } from "@angular/router";
import { SessionComponent } from "./session/session.component";
import { SharedModule } from "../../../shared/shared.module";
import { UpdateMailDialogComponent } from "./update-mail-dialog/update-mail-dialog.component";
import {CommonModule} from "@angular/common";

const routes = [
  {
    path: "login",
    component: FuseLoginComponent,
    canActivate: [IsUserAnonymous, CanAccessToAuthenticationFeaturesGuard],
  },
  {
    path: "logout",
    component: LogoutComponent,
    canActivate: [IsUserLogged, CanAccessToAuthenticationFeaturesGuard],
  },
  {
    path: "forgot-password",
    component: FuseForgotPasswordComponent,
    canActivate: [CanAccessToAuthenticationFeaturesGuard],
  },
  {
    path: "user/reset/:token",
    component: FuseResetPasswordComponent,
    canActivate: [CanAccessToAuthenticationFeaturesGuard],
  },
  {
    path: "connect-via-gar",
    component: ConnectViaGarComponent,
  },
  {
    path: "session",
    component: SessionComponent,
  },
  {
    path: "password-reset-email",
    component: FusePasswordResetEmailComponent,
    canActivate: [CanAccessToAuthenticationFeaturesGuard],
  },
  {
    path: "connect",
    component: ManageConnectPageComponent,
    canActivate: [CanAccessToAuthenticationFeaturesGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FuseSharedModule,
    SharedModule,
    RouterModule.forChild(routes),
    MatProgressSpinnerModule,
  ],
  declarations: [
    FuseLoginComponent,
    FuseForgotPasswordComponent,
    SessionComponent,
    ConnectViaGarComponent,
    FuseResetPasswordComponent,
    FusePasswordResetEmailComponent,
    UpdateMailDialogComponent,
    LogoutComponent,
    RegistrationCodeDirective,
    QuickConnectPageComponent,
    ManageConnectPageComponent,
  ],
  providers: [],
})
export class AuthenticationModule {
  static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [AuthenticationService],
    };
  }
}
