import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export interface ButtonListDialogData {
    title: string,
    list: {
        label: string, value: string
    }[]
}

@Component({
    selector: 'app-button-list-dialog',
    templateUrl: './button-list-dialog.component.html',
    styleUrls: ['./button-list-dialog.component.scss']
})
export class ButtonListDialogComponent implements OnInit {

    constructor(
        private ref: MatDialogRef<ButtonListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ButtonListDialogData) {
    }

    ngOnInit(): void {
    }

}
