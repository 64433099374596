export const dashboard = {
    alwaysShowDashboard: true,
    displayHeaderLink: true,
    displayedWidgets: {
        default: [
            'assignmentsClosed',
            'learnersWithoutAssignments'
        ],
        learner: ['assignments'],
        trainer: ['news', 'assignmentsClosed', 'learnersWithoutAssignments', 'recommendation'],
        manager: ['analytics', 'assignments', 'recommendation'],
        administrator: [
            'group',
            'assignmentsGroup',
            'analytics',
            'assignments',
            'recommendation'
        ]
    },
    newsLimit: 1
};
